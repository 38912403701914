import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Book } from "../types";
import { RootState } from "../store";
import { getCategories, filterByCategory, sortBooks } from "./utils";
import { fetchBooksAsync, fetchBooksWithPolling } from "./libraryThunks";

type LibraryState = {
  books?: Book[];
  categories?: string[];
  chosenCategory?: string;
};

const initialState: LibraryState = {};

const librarySlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    setCategory: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload !== undefined && !state.categories?.includes(action.payload)) {
        throw new Error(`Category ${action.payload} does not exist`);
      }
      state.chosenCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBooksAsync.fulfilled, (state, action) => {
        state.books = sortBooks(action.payload);
        state.categories = getCategories(action.payload);
      })
      .addCase(fetchBooksWithPolling.fulfilled, (state, action) => {});
  },
});

export const { setCategory } = librarySlice.actions;

export const selectBooks = (state: RootState): Book[] | undefined => {
  const books = state.library.books;
  if (!books) {
    return undefined;
  } else if (!state.library.chosenCategory) {
    return books;
  } else {
    return filterByCategory(state.library.chosenCategory, books);
  }
};

export const selectCategories = (state: RootState): string[] | undefined => {
  return state.library.categories;
};

export const selectChosenCategory = (state: RootState): string | undefined => {
  return state.library.chosenCategory;
};

export default librarySlice.reducer;

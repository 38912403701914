import React, { useRef, useEffect } from "react";

import { BookPosition } from "../../../types";
import { retrieveHTMLRef } from "../../utils";
import { useConversation } from "../../../context/ConversationContext";
import { SENDERS } from "../../../reader/types";

import MessageItem from "./MessageItem";
import TypingIndicator from "./TypingIndicator";

type MessageAreaProps = {
  onSourceClick: (location: BookPosition) => void;
};

const MessageArea = ({
  onSourceClick,
}: MessageAreaProps): JSX.Element => {
  const {
    book,
    messages,
    isTyping,
    selectedModel,
    showDelayIndicator,
    handleUserMessage,
  } = useConversation();

  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    retrieveHTMLRef(messagesEndRef)?.scrollIntoView({ behavior: "smooth" });
  }, [messages, isTyping]);

  const getAIMessageMenuItems = (index: number): string[] | undefined => {
    // Only show menu items for the last message and only for the sources tutor model
    if (index === messages.length - 1 && selectedModel.slug === "sources_tutor") {
      // Show different options for the first message
      if (index === 0) return [`What is the central idea of ${book.title}?`, "Explain how you can help me"];
      else return ["I don't know", "Explain this to me like I'm 5"];
    }
    return undefined;
  };

  return (
    <div className="flex flex-col space-y-2 flex-grow p-3 pb-16">
      {messages.map((message, index) => (
        <MessageItem
          key={index}
          index={index}
          message={message}
          modelName={selectedModel.name}
          book={book}
          onSourceClick={onSourceClick}
          menuItems={message.sender === SENDERS.AI ? getAIMessageMenuItems(index) : undefined}
          handleUserMessage={handleUserMessage}
        />
      ))}
      {isTyping && <TypingIndicator modelName={selectedModel.name} showDelayIndicator={showDelayIndicator} />}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageArea;

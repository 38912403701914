type ConfigParams = {
  REACT_APP_BACKEND_URL: string,
  REACT_APP_CLERK_PUBLISHABLE_KEY: string,
  REACT_APP_SUPABASE_ID: string,
  ENVIRONMENT: string,
};


// A helper function to validate the environment variables
const validateEnv = (variable: string): string => {
  const value = process.env[variable];

  if (typeof value === "undefined") {
    throw new Error(`Environment variable ${variable} is not set`);
  }

  return value;
};

export const config: ConfigParams = {
  REACT_APP_BACKEND_URL: validateEnv("REACT_APP_BACKEND_URL"),
  REACT_APP_CLERK_PUBLISHABLE_KEY: validateEnv("REACT_APP_CLERK_PUBLISHABLE_KEY"),
  REACT_APP_SUPABASE_ID: validateEnv("REACT_APP_SUPABASE_ID"),
  ENVIRONMENT: validateEnv("NODE_ENV"),
};

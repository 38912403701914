import React from "react";
import { useNavigate } from "react-router-dom";
import { Flex } from "@mantine/core";

import { Book } from "../../types";
import { useAppDispatch } from "../../state/hooks";
import { openBook } from "../../state/readerSlice";

import AddBookTile from "./AddBookTile";

type BookListProps = {
  books: Book[];
  onBookSelected: () => void;
};

// TODO : change the current book in the Redux state when a book is clicked

const BookList = ({ books, onBookSelected }: BookListProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClick = (book: Book): void => {
    if (book.isEmbedded) {
      dispatch(openBook({ book }));
      navigate(`/book/${book.slug}`);
      onBookSelected();
    }
  };

  return (
    <Flex
      p={20}
      gap="xl"
      wrap="wrap"
    >
      {books.length === 0 && (
        <div className="text-center w-full">
          <p className="text-gray-500">Loading...</p>
        </div>
      )}
      {books.length > 0 && books.map((book) => (
        <div key={book.slug} className="text-center w-64">
          <div
            onClick={() => handleClick(book)}
            className="hover:drop-shadow-2xl transition duration-300 ease-in-out text-center cursor-pointer"
          >
            <div className="absolute mt-4">
              {!book.isEmbedded && (
                <div className="px-2 py-0.5 mt-2 bg-gray-300 text-gray-600 rounded-r-md">
                  Processing...
                </div>
              )}
              {!book.isPublic && (
                <div className="px-2 py-0.5 mt-2 bg-blue-300 text-gray-600 rounded-r-md">
                  Private to you
                </div>
              )}
            </div>
            <img
              className="inline"
              src={book.cover}
              width="120"
              alt={`Cover for ${book.title}`}
              onError={(e) => {
                (e.target as HTMLImageElement).src = "/no-cover.png";
              }}
            />
            <p className="pt-1">{book.title}</p>
          </div>
        </div>
      ))}
      <div className="text-center w-64">
        <AddBookTile />
      </div>
    </Flex>
  );
};

export default BookList;

import { ApiGetRequest } from "../hooks/useApiCaller";
import { User } from "../types";

type UserResponse = {
  id: string;
  created_at: string;
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  image_url: string;
  collections: string[];
};

const processUser = (user: UserResponse): User => {
  return {
    id: user["id"],
    createdAt: user["created_at"],
    email: user["email"],
    firstName: user["first_name"],
    lastName: user["last_name"],
    username: user["username"],
    imageUrl: user["image_url"],
    collections: user["collections"],
  };
};

export const getUsers = async (
  getFn: ApiGetRequest<UserResponse[]>
): Promise<User[]> => {
  const users = await getFn("/api/users");
  return users.map(processUser);
};

import React from "react";
import { useDisclosure } from "@mantine/hooks";
import { ActionIcon, Box, Drawer, Flex } from "@mantine/core";
import { IconBooks, IconList } from "@tabler/icons-react";
// import { UserButton } from "@clerk/clerk-react";
import "@react-pdf-viewer/bookmark/lib/styles/index.css";

import { BOOK_FORMATS } from "../../reader/BookReader";
import { useAppSelector } from "../../state/hooks";
import { selectBooks } from "../../state/librarySlice";
import { useBook } from "../../hooks/useBook";
import { useTOC } from "../../context/TOCContext";

import BookList from "../content/BookList";
import TOCComponent from "../content/TOC";

enum TABS { LIBRARY, TABLE_OF_CONTENTS };

const COLORS = {
  ACTIVE: "white",
  INACTIVE: "white",
};

const CONTROLS = {
  TOP: 20,
  LEFT: 20,
  ICON_PROPS: {
    size: 30,
    variant: "outline",
  },
};

const DRAWER = {
  WIDTH: 300,
};

const APPEAR_TRANSITION = "left 0.2s ease";
const BACKGROUND_TRANSITION = "background-color 0.2s ease";

const DefaultAside = (): JSX.Element => {
  const [opened, { open, close }] = useDisclosure(false);
  const [activeTab, setActiveTab] = React.useState<TABS>(TABS.LIBRARY);

  const { book } = useBook();
  const visibleBooks = useAppSelector(selectBooks);

  const onTabToggle = (clickedTab: TABS): void => {
    if (opened && clickedTab === activeTab) {
      close();
    } else {
      setActiveTab(clickedTab);
      open();
    }
  };

  const { bookmarkPluginInstance } = useTOC();
  const { Bookmarks } = bookmarkPluginInstance;
  const TableOfContents = book?.format === BOOK_FORMATS.PDF ? Bookmarks : TOCComponent;

  return (
    <>
      <Box
        top={CONTROLS.TOP}
        left={CONTROLS.LEFT}
        style={{
          position: "absolute",
          transition: APPEAR_TRANSITION,
          width: "75px",
          height: "20px",
          display: "flex",
          justifyContent: "space-between",
          zIndex: 1000,
        }}
      >
        <ActionIcon
          onClick={() => onTabToggle(TABS.LIBRARY)}
          style={{
            backgroundColor: opened && activeTab === TABS.LIBRARY ? COLORS.ACTIVE : COLORS.INACTIVE,
            transition: BACKGROUND_TRANSITION,
          }}
          {...CONTROLS.ICON_PROPS}
        >
          <IconBooks />
        </ActionIcon>
        <ActionIcon
          onClick={() => onTabToggle(TABS.TABLE_OF_CONTENTS)}
          style={{
            backgroundColor: opened && activeTab === TABS.TABLE_OF_CONTENTS ? COLORS.ACTIVE : COLORS.INACTIVE,
            transition: BACKGROUND_TRANSITION,
          }}
          {...CONTROLS.ICON_PROPS}
        >
          <IconList />
        </ActionIcon>
      </Box>

      <Drawer
        size={DRAWER.WIDTH}
        position="left"
        opened={opened}
        onClose={close}
        keepMounted={true}
        style={{
          transition: APPEAR_TRANSITION,
        }}
        closeButtonProps={{
          size: "lg",
        }}
      >
        <Flex
          direction="column"
          align="space-between"
          pt={10}
          pl={4}
        >
          <Box>
            {activeTab === TABS.LIBRARY && (
              <BookList
                books={visibleBooks || []}
                onBookSelected={close}
              />
            )}
            {activeTab === TABS.TABLE_OF_CONTENTS && (
              <TableOfContents />
            )}
          </Box>
          {/* <Box>
            <UserButton />
          </Box> */}
        </Flex>
      </Drawer>
    </>
  );
};

export default DefaultAside;

import { createAsyncThunk } from "@reduxjs/toolkit";

import { Book } from "../types";

import { ApiCaller } from "../hooks/useApiCaller";
import { getBooks } from "../api/books";
import { logger } from "../tools/logger";

const SECOND = 1000;

enum ERRORS {
  FAILURE = "Connection or server failure",
}

type ThunkArgs = {
  apiCaller: ApiCaller;
};

export const fetchBooksAsync = createAsyncThunk<Book[], ThunkArgs>(
  "library/fetchBooks",
  async ({ apiCaller }: ThunkArgs, { rejectWithValue }) => {
    try {
      return await getBooks(apiCaller.get);
    } catch (e) {
      logger.error("Error while fetching books", e);
      return rejectWithValue(ERRORS.FAILURE);
    }
  },
);

export const fetchBooksWithPolling = createAsyncThunk<Book[], ThunkArgs>(
  "library/fetchBooksWithPolling",
  async ({ apiCaller }: ThunkArgs, { dispatch }) => {
    while (true) {
      await dispatch(fetchBooksAsync({ apiCaller }));
      await new Promise((resolve) => setTimeout(resolve, 10 * SECOND));
    }
  },
);

import React, { useState } from "react";
import { Alert, Box, Button, Divider, Group, FileInput, TextInput, Checkbox } from "@mantine/core";
import { useForm } from "@mantine/form";

import { newBookSchema } from "../../validation/newBookSchema";
import { addBook } from "../../api/books";
import { slugify, slugifyAndPack, splitAndFilter } from "../../pages/admin/utils";
import { useApiCaller } from "../../hooks/useApiCaller";

type FormValues = {
  bookFile: File | undefined;
  coverFile: File | undefined;
  title: string;
  authors: string;
  categories: string;
  isPublic: boolean;
};

const AddBookForm = (): JSX.Element => {
  const { post } = useApiCaller();

  const [status, setStatus] = useState<"idle" | "loading" | "success" | "error">("idle");
  const [error, setError] = useState<string>();

  const form = useForm({
    initialValues: {
      bookFile: undefined,
      coverFile: undefined,
      title: "",
      authors: "",
      categories: "",
    } as FormValues,
  });

  const onFormSubmit = async (values: typeof form["values"]): Promise<void> => {
    const title = values.title || values.bookFile?.name.split(".").shift() || "";
    const bookData = newBookSchema.validateSync({
      "book_file": values.bookFile,
      "cover_file": values.coverFile,
      "slug": slugify(title),
      "title": title,
      "format": values.bookFile?.name.split(".").pop(),
      "authors": slugifyAndPack(splitAndFilter(values.authors)),
      "categories": slugifyAndPack(splitAndFilter(values.categories)),
      "has_chat_interface": true,
      "has_chat_sources": true,
      "is_public": values.isPublic,
      "position": null,
      "custom_cover": null,
    });

    try {
      setStatus("loading");
      await addBook(post, bookData);
      setStatus("success");
    } catch (error) {
      setStatus("error");
      setError(`Error: ${error}`);
      console.error(error);
    }
  };

  if (status === "idle") {
    return (
      <Box maw={500} mx="auto">
        <form onSubmit={form.onSubmit(onFormSubmit)}>
          <FileInput
            required
            withAsterisk
            accept=".pdf,.epub"
            label="Book file"
            placeholder="Select a PDF or EPUB file"
            {...form.getInputProps("bookFile")}
          />
          {form.values.bookFile && (
            <>
              <Divider variant="dashed" mt={24} mb={16} mx={50} />
              <TextInput
                label="Title (optional)"
                placeholder="Enter the book title"
                {...form.getInputProps("title")}
              />
              <TextInput
                label="Authors (optional)"
                placeholder="Enter the book authors (comma-separated)"
                {...form.getInputProps("authors")}
              />
              <TextInput
                label="Categories (optional)"
                placeholder="Enter the book categories (comma-separated)"
                {...form.getInputProps("categories")}
              />
              <FileInput
                accept="image/*"
                label="Cover image (optional)"
                placeholder="Select a cover image"
                {...form.getInputProps("coverFile")}/>

              <Checkbox
                label="Make public"
                {...form.getInputProps("isPublic")}/>
            </>
          )}

          <Group position="right" mt={20}>
            <Button
              type="submit"
              variant="default"
              color="blue"
              disabled={!form.values.bookFile}
            >
              Upload
            </Button>
          </Group>
        </form>
      </Box>
    );
  } else if (status === "loading") {
    return (
      <Alert color="blue" title="Uploading..." className="w-64 mx-auto">
        <p className="text-center">Please wait while your book is being uploaded...</p>
      </Alert>
    );
  } else if (status === "success") {
    return (
      <Alert color="green" title="Success!" className="w-64 mx-auto">
        <p className="text-center">Your book has been uploaded successfully!</p>
      </Alert>
    );
  } else {
    return (
      <Alert color="red" title="Error!" className="w-64 mx-auto">
        <p className="text-center">{error}</p>
      </Alert>
    );
  }

};

export default AddBookForm;

import axios from "axios";
import { config } from "../config";
import { logger } from "../tools/logger";
import { useAuth } from "@clerk/clerk-react";

// TODO : stop exporting this enum
export enum METHODS {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
}

export type Payload = { [key: string]: any } | undefined;
export type SessionToken = string | null; // TODO : stop exporting this type

type RequestOptions = {
  upload: boolean;
  uploadFilenames: string[];
};

const DEFAULT_OPTIONS: RequestOptions = {
  upload: false,
  uploadFilenames: [],
};

const prepareFormData = (data: Payload, fieldsToUpload: string[]): FormData => {
  const formData = new FormData();
  if (!data) return formData;

  fieldsToUpload.forEach((field) => {
    if (data[field]) {
      formData.append(field, data[field]);
      delete data[field];
    }
  });

  formData.append("data", JSON.stringify(data));
  return formData;
};

// TODO : stop exporting this function directly, export only the hook
export const apiCall = async (
  method: METHODS,
  url: string,
  data: Payload,
  sessionToken: SessionToken,
  options: RequestOptions = DEFAULT_OPTIONS, // TODO! : remove default value when `apiCall` is no longer exported
): Promise<any> => {
  if (!sessionToken) throw new Error("No session token provided");
  if (!url.startsWith("/")) throw new Error("URL must start with /");
  if (url.includes(" ")) throw new Error("URL must not contain spaces");

  const endpoint = config.REACT_APP_BACKEND_URL + url;
  logger.log(`requesting endpoint: ${endpoint}`);

  const preparedData = options.upload ? prepareFormData(data, options.uploadFilenames) : data;

  try {
    const response = await axios({
      method,
      url: endpoint,
      data: preparedData,
      headers: {
        "Accept": "application/json",
        "Content-Type": options.upload ? "multipart/form-data" : "application/json",
        "Authorization": `Bearer ${sessionToken}`,
      },
    });

    return response.data;
  } catch (error: any) {
    logger.error(`Error in API call to ${url}:`, error.response?.data?.detail ?? "No error message");
    throw error;
    // TODO : show some kind of error message to the user
  }
};

type GetRequest<T = any> = (url: string) => Promise<T>;
type PostRequest<T = any> = (url: string, data: Payload, options?: Partial<RequestOptions>) => Promise<T>;
type PutRequest<T = any> = (url: string, data: Payload) => Promise<T>;
type DeleteRequest<T = any> = (url: string) => Promise<T>;

// This is needed to make the types distinguishable as their signatures match
export type ApiGetRequest<T = any> = GetRequest<T> & { __type: "ApiGetRequest" };
export type ApiPostRequest<T = any> = PostRequest<T> & { __type: "ApiPostRequest" };
export type ApiPutRequest<T = any> = PutRequest<T> & { __type: "ApiPutRequest" };
export type ApiDeleteRequest<T = any> = DeleteRequest<T> & { __type: "ApiDeleteRequest" };

export type ApiCaller = {
  get: ApiGetRequest;
  post: ApiPostRequest;
  put: ApiPutRequest;
  del: ApiDeleteRequest;
};

export const useApiCaller = (): ApiCaller => {
  const { getToken } = useAuth();

  const request = async (
    method: METHODS,
    url: string,
    data?: Payload,
    options?: Partial<RequestOptions>,
  ): Promise<any> => {
    const sessionToken = await getToken();
    const fullOptions = { ...DEFAULT_OPTIONS, ...options };
    return apiCall(method, url, data, sessionToken, fullOptions);
  };

  return {
    get: async (url: string) => {
      return request(METHODS.GET, url);
    },
    post: async (url: string, data: Payload, options?: Partial<RequestOptions>) => {
      return request(METHODS.POST, url, data, options);
    },
    put: async (url: string, data: Payload) => {
      return request(METHODS.PUT, url, data);
    },
    del: async (url: string) => {
      return request(METHODS.DELETE, url);
    },
  } as ApiCaller;
};

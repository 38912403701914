import React from "react";
import { AppShell } from "@mantine/core";

import { Childful } from "../../types";

import DefaultAside from "./DefaultAside";

const DefaultLayout = ({ children }: Childful): JSX.Element => {
  return (
    <AppShell
      padding={0}
      aside={<DefaultAside />}
    >
      {children}
    </AppShell>
  );
};

export default DefaultLayout;

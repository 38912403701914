import React, { createContext, useContext } from "react";
import { bookmarkPlugin, BookmarkPlugin } from "@react-pdf-viewer/bookmark";

import { Childful } from "../types";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { selectTOC, setTOC as setTOCState } from "../state/tocSlice";
import { TOCItem } from "../types";

type TOCContextType = {
  bookmarkPluginInstance: BookmarkPlugin;
  toc: TOCItem[] | undefined;
  setTOC: (toc: TOCItem[]) => void;
};

const TOCContext = createContext<TOCContextType>(undefined!);

export const useTOC = (): TOCContextType => {
  return useContext(TOCContext);
};

export const TOCProvider = ({ children }: Childful): JSX.Element => {
  const bookmarkPluginInstance = bookmarkPlugin();

  const dispatch = useAppDispatch();
  const toc = useAppSelector(selectTOC);

  const setTOC = (toc: TOCItem[]): void => {
    dispatch(setTOCState(toc));
  };

  return (
    <TOCContext.Provider value={{ bookmarkPluginInstance, toc, setTOC }}>
      {children}
    </TOCContext.Provider>
  );
};

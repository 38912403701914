import { ConversationMessage } from "../reader/types";
import { Conversation, Conversations } from "../reader/types";
import { ApiGetRequest, ApiPostRequest, ApiDeleteRequest } from "../hooks/useApiCaller";

export type SavedConversation = {
  id: string;
};

export const saveConversation = async (
  postFn: ApiPostRequest<SavedConversation>,
  conversation: Conversation,
): Promise<Conversation> => {
  await postFn(`/api/conversations?book-id=${conversation.bookId}`, {
    "id": conversation.id,
    "chat_model_slug": conversation.chatModelSlug,
    "messages": conversation.messages,
  });
  return conversation;
};

type ServerConversation = {
  id: string;
  book_id: string;
  chat_model_slug: string;
  messages: ConversationMessage[];
};

export const getConversations = async (
  getFn: ApiGetRequest<ServerConversation[]>,
  bookId: string,
): Promise<Conversations> => {
  const response = await getFn(`/api/conversations?book-id=${bookId}`);
  return response.reduce((acc: Conversations, conversation: ServerConversation) => {
    acc[conversation.id] = {
      id: conversation["id"],
      bookId: conversation["book_id"],
      chatModelSlug: conversation["chat_model_slug"],
      messages: conversation["messages"],
    };
    return acc;
  }, {});
};

// TODO: implement this
export const deleteConversation = async (
  deleteFn: ApiDeleteRequest,
  conversationId: string,
): Promise<void> => {
  return await deleteFn(`/api/conversations/${conversationId}`);
};

import { array, boolean, mixed, object, string, InferType } from "yup";
import { BOOK_FORMATS } from "../reader/BookReader";
import { emptyStringToNull } from "./utils";

const FORMATS = Object.values(BOOK_FORMATS);
const SLUG_REGEX = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
const PNG_FILENAME_REGEX = /^[a-z0-9-]+\.png$/;

export type BookData = InferType<typeof newBookSchema>;
export const newBookSchema = object({
  "book_file": mixed<File>()
    .required("File is required"),

  "cover_file": mixed<File>(),

  "slug": string()
    .required("Slug is required")
    .matches(SLUG_REGEX, "Slug must be lowercase and hypenated"),

  "title": string()
    .required("Title is required"),

  "format": string()
    .required("Format is required")
    .oneOf(FORMATS, `Format must be one of: ${FORMATS.join(", ")}`),

  "authors": array()
    .min(0, "You should list at least one author")
    .of(object({
      "name": string()
        .required("Author name is required"),
      "slug": string()
        .required("Author slug is required")
        .matches(SLUG_REGEX, "Author slug must be lowercase and hypenated"),
    })),

  "categories": array()
    .min(0, "You should list at least one category").of(object({
      "name": string()
        .required("Category name is required"),
      "slug": string()
        .required("Category slug is required")
        .matches(SLUG_REGEX, "Category slug must be lowercase and hypenated"),
    })),

  "has_chat_interface": boolean()
    .required("Chat interface is required to be explicitly true or false"),

  "has_chat_sources": boolean()
    .required("Chat sources is required to be explicitly true or false"),

  "is_public": boolean()
    .notRequired(),

  "position": string()
    .notRequired()
    .nullable()
    .transform(emptyStringToNull),

  "custom_cover": string()
    .notRequired()
    .nullable()
    .transform(emptyStringToNull)
    .matches(PNG_FILENAME_REGEX, "Custom cover must be the name of a PNG file"),
});

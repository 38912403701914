import { BookPosition } from "../types";

export type BookSelection = {
  selectionRange: BookPosition;
  text: string;
};

export type Highlight = BookSelection & {
  id: string;
};

export type Highlights = {
  [key: string]: Highlight;
};

export type Conversations = {
  [key: string]: Conversation;
};

export type Conversation = {
  id: string;
  bookId: string;
  chatModelSlug: string;
  messages: ConversationMessage[];
};

export enum SENDERS {
  AI = "ai",
  USER = "user",
}

export type ConversationMessage = {
  id: string;
  sender_slug: SENDERS;
  text: string;
  timestamp: Date;
  sources?: string;
};

export type ConversationLog = {
  question: string;
  response: string;
  conversationId: string;
  bookId: string;
};

export type Message = {
  id: string;
  sender: SENDERS;
  text: string;
  sources?: string;
  timestamp: Date;
  // Messages that are streamed get updated chunk by chunk. This flag is used to
  // indicate that the message is complete.
  isComplete: boolean;
};

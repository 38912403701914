export const REACT_READER_THEMES = {
  DEFAULT: {
    "::selection": {
      background: "#FFFF00",
    },
  },
  CUSTOM: {
    p: {
      margin: "0",
      "padding-top": "1em",
      "font-family": "Inter",
    },

    span: {
      transition: "all 250ms",
    },

    ".span:hover": {
      background: "rgba(77, 137, 255, 30%)",
    },

    ".interaction-div": {
      hidden: "true",
      // position: "absolute",
      // top: "-0.6em",
      // left: "0px",
      background: "rgba(255, 255, 255, 0.2)",
    },

    ".generator-button": {
      background: "none",
      border: "none",
      "border-right": "1px solid #DEDEDE",
      "border-radius": "3px",
      color: "black",
      padding: "5px",
      //transition: "all 250ms",
      cursor: "pointer",
      //"-webkit-animation": "fadein 4s"
    },

    //no border right on this one
    ".generator-button-rightmost": {
      background: "none",
      border: "none",
      "border-radius": "3px",
      color: "black",
      padding: "5px",
      //transition: "all 250ms",
      cursor: "pointer",
      //"-webkit-animation": "fadein 4s"
    },

    ".generator-button:hover": {
      color: "#4D89FF",
    },

    ".generator-button:active": {
      background: "none",
      color: "#4D89FF",
    },

    ".generator-button-rightmost:hover": {
      background: "none",
      color: "#4D89FF",
    },

    ".interactionButtonHighlight": {
      background: "none",
      color: "#4D89FF",
    },

    ".paragraph-wrapper": {
      position: "relative",
    },
  },
};

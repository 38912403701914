import { ConversationMessage, Message } from "./types";


export function convertToConversationMessage(message: Message): ConversationMessage {
  return {
    id: message.id,
    // eslint-disable-next-line camelcase
    sender_slug: message.sender,
    text: message.text,
    timestamp: message.timestamp,
    sources: message.sources,
  };
}

export function convertToConversationMessages(messages: Message[]): ConversationMessage[] {
  return messages.map((message) => {
    return convertToConversationMessage(message);
  },
  );
};




export function convertToMessage(message: ConversationMessage): Message {
  return {
    id: message.id,
    sender: message["sender_slug"],
    text: message.text,
    // TODO: get correct date to working
    timestamp: new Date(),
    sources: message.sources,
    isComplete: true,
  };
};

export function convertToMessages(messages: ConversationMessage[]): Message[] {
  return messages.map((message) => {
    return convertToMessage(message);
  },
  );
};

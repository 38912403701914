import { flatten, identity, map, pipe, prop, sortBy, uniq } from "ramda";
import { Book } from "../types";

export const getCategories: (books: Book[]) => string[] = pipe(
  map(prop("categories")),
  flatten,
  uniq,
  sortBy(identity),
);

export const filterByCategory = (category: string, books: Book[]): Book[] => {
  return books.filter((book) => book.categories.includes(category));
};

export const sortBooks = (books: Book[]): Book[] => {
  return books.sort((a, b) => {
    if (a.isPublic && !b.isPublic) {
      return -1;
    } else if (!a.isPublic && b.isPublic) {
      return 1;
    } else {
      return a.title.localeCompare(b.title);
    }
  });
};

import React from "react";
import { TOCItem } from "../../types";
import { useBook } from "../../hooks/useBook";
import { useTOC } from "../../context/TOCContext";

type TOCItemComponentProps = {
  item: TOCItem;
};

const TOCItemComponent = ({ item }: TOCItemComponentProps): JSX.Element => {
  const { setBookPosition } = useBook();
  return (
    <div>
      <button onClick={() => setBookPosition(item.href)} className="hover:underline text-left">
        {item.label}
      </button>
      {item.subitems && item.subitems.length > 0 && (
        <div className="ml-4 flex flex-col gap-2 pt-2">
          {item.subitems.map((subItem) => (
            <TOCItemComponent key={subItem.id} item={subItem} />
          ))}
        </div>
      )}
    </div>
  );
};

const TOC = (): JSX.Element => {
  const { toc } = useTOC();

  return (
    <div className="flex flex-col gap-2">
      {toc && toc.map((item: TOCItem) => <TOCItemComponent key={item.id} item={item} />)}
      {!toc && <div>This document does not have a Table of Contents</div>}
    </div>
  );
};

export default TOC;

import React from "react";

type TextInputProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  placeholder?: string;
};

const TextInput = ({ value, onChange, label, placeholder }: TextInputProps): JSX.Element => {
  return (
    <input
      type="text"
      className="block w-full px-4 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded appearance-none focus:outline-none focus:border-blue-500"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default TextInput;

import React, { useRef, useEffect } from "react";
import { HiOutlinePaperAirplane } from "react-icons/hi";
import { MoonIcon } from "@radix-ui/react-icons";

import { retrieveHTMLRef } from "../../utils";
import { useConversation } from "../../../context/ConversationContext";

const MessageInput = (): JSX.Element => {
  const { inputMessage, handleInputChange, handleUserMessage } = useConversation();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    retrieveHTMLRef(inputRef)?.focus();
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleUserMessage();
      const input = retrieveHTMLRef(inputRef);
      if (input) input.innerText = "";
    }
  };


  const handleTextPaste = (event: React.ClipboardEvent<HTMLSpanElement>): void => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  };

  const handleTextDrop = (event: React.DragEvent<HTMLSpanElement>): void => {
    event.preventDefault();
    const target = event.target as HTMLElement;
    const text = event.dataTransfer.getData("text/plain");
    target.focus();
    document.execCommand("insertHTML", false, text);
  };

  return (
    <div className="flex sticky bottom-0 bg-white items-center pt-0">
      <div className="flex flex-grow items-end border-gray-100 border rounded-md resize-none">
        <span
          contentEditable={true}
          ref={inputRef}
          className="rounded p-2 text-sm resize-none w-full pl-4 pr-10 border-0 focus:outline-none"
          value={inputMessage} // TODO! : fix typing
          onInput={handleInputChange}
          onKeyDown={handleKeyDown}
          onPaste={handleTextPaste}
          onDrop={handleTextDrop}
          data-gramm="false"
        />

        <button className="p-2 text-yellow-600" onClick={() => {
          handleUserMessage();
          const input = retrieveHTMLRef(inputRef);
          if (input) input.innerText = "";
        }}>
          {/* A little splash of encouragement" */}
          <MoonIcon className="w-4 h-4" />
          {/* <HiOutlinePaperAirplane className="w-4 h-4" /> */}
        </button>
      </div>
    </div>
  );
};

export default MessageInput;

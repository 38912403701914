import {
  ApiGetRequest,
  ApiPostRequest,
  ApiPutRequest,
  ApiDeleteRequest,
} from "../hooks/useApiCaller";
import { Collection, Book, User } from "../types";

async function getCollections(
  getFn: ApiGetRequest<Collection[]>
): Promise<Collection[]> {
  const collections = await getFn("/api/collections");
  return collections;
}

async function postCollection(
  postFn: ApiPostRequest<Collection>,
  collection: Collection
): Promise<Collection> {
  const newCollection = await postFn("/api/collections", collection);
  return newCollection;
}

async function updateCollection(
  putFn: ApiPutRequest<Collection>,
  collection: Collection
): Promise<Collection> {
  const updatedCollection = await putFn(
    `/api/collections/${collection.slug}`,
    {}
  );
  return updatedCollection;
}

async function deleteCollection(
  deleteFn: ApiDeleteRequest<Collection>,
  collection: Collection
): Promise<Collection> {
  const deletedCollection = await deleteFn(
    `/api/collections/${collection.slug}`
  );
  return deletedCollection;
}

async function postBookToCollection(
  postFn: ApiPostRequest<Collection>,
  collection: Collection,
  book: Book
): Promise<Collection> {
  const newCollection = await postFn(
    `/api/collections/${collection.slug}/books/${book.slug}`,
    {}
  );
  return newCollection;
}

async function putBookInCollection(
  putFn: ApiPutRequest<Collection>,
  collection: Collection,
  book: Book
): Promise<Collection> {
  const updatedCollection = await putFn(
    `/api/collections/${collection.slug}/books/${book.slug}`,
    {}
  );
  return updatedCollection;
}

async function deleteBookFromCollection(
  deleteFn: ApiDeleteRequest<Collection>,
  collection: Collection,
  book: Book
): Promise<Collection> {
  const deletedCollection = await deleteFn(
    `/api/collections/${collection.slug}/books/${book.slug}`
  );
  return deletedCollection;
}

async function putUserInCollection(
  putFn: ApiPutRequest<Collection>,
  collection: Collection,
  user: User
): Promise<Collection> {
  const newCollection = await putFn(
    `/api/users/${user.id}/collections/${collection.id}`,
    {}
  );
  return newCollection;
}

async function deleteUserFromCollection(
  deleteFn: ApiDeleteRequest<Collection>,
  collection: Collection,
  user: User
): Promise<Collection> {
  const deletedCollection = await deleteFn(
    `/api/users/${user.id}/collections/${collection.id}`
  );
  return deletedCollection;
}

export {
  getCollections,
  postCollection,
  updateCollection,
  deleteCollection,
  postBookToCollection,
  putBookInCollection,
  deleteBookFromCollection,
  putUserInCollection,
  deleteUserFromCollection,
};

import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuth } from "@clerk/clerk-react";

import { Childful } from "../types";
import { logger } from "../tools/logger";
import { hasApiKey } from "../api/keys";

type ApiKeyContextType = {
  hasKey: boolean;
  setHasKey: (hasKey: boolean) => void;
};

const ApiKeyContext = createContext<ApiKeyContextType>(undefined!);

export const useApiKey = () => {
  return useContext(ApiKeyContext);
};

export const ApiKeyProvider = ({ children }: Childful) => {
  const [hasKey, setHasKey] = useState<boolean>(false);
  const { getToken } = useAuth();

  useEffect(() => {
    const fetchHasKey = async () => {
      try {
        const token = await getToken();
        const result = await hasApiKey(token);
        setHasKey(result);
      } catch (error) {
        logger.error("Error fetching hasKey:", error);
      }
    };

    fetchHasKey();
  }, [getToken]);

  return (
    <ApiKeyContext.Provider value={{ hasKey, setHasKey }}>
      {children}
    </ApiKeyContext.Provider>
  );
};

import React, { useEffect, useRef, useState } from "react";
import { ReactReader, ReactReaderStyle } from "react-reader";
import { Contents, Rendition } from "epubjs";

import { BookPosition } from "../../types";
import { BookSelection, Highlights } from "../types";

import { logger } from "../../tools/logger";
import { REACT_READER_THEMES } from "./ReactReaderThemes";

import { useTOC } from "../../context/TOCContext";

type EpubJsSelection = {
  cfiRange: string;
  contents: Contents;
};

const EPUB_JS_DELAY = 300;
const HIGHLIGHT_COLOR = "#FFFF00";

// DO NOT REMOVE (until ReactReader is no longer used)
export const preventReactReaderNavigation = (event: KeyboardEvent): void => {
  if (event.key.includes("Arrow")) {
    event.stopPropagation();
  }
};

// TODO : can we just use the character "✓" instead?
function makeSvg(): SVGElement {
  let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("width", "15");
  svg.setAttribute("height", "10");
  svg.setAttribute("display", "none");
  let checkMark = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "path",
  );
  checkMark.setAttribute("d", "M0.666656 4.5L3.66666 7.5L9.66666 1.5");
  checkMark.setAttribute("transform", "translate(4)");
  checkMark.setAttribute("stroke", "#4D89FF");
  checkMark.setAttribute("fill", "none");
  svg.appendChild(checkMark);

  return svg;
}

const convertToAnnotationKey = (cfi: string): string => {
  cfi = cfi.replace(/\[/g, "%5B");
  cfi = cfi.replace(/\]/g, "%5D");
  return cfi + "highlight";
};

const convertFromAnnotationKey = (key: string): string => {
  key = key.replace(/%5B/g, "[");
  key = key.replace(/%5D/g, "]");
  key = key.replace(/highlight$/, "");
  return key;
};

const wrapSingleCfiRange = (cfiRange: BookPosition | undefined): Highlights => {
  if (!cfiRange) return {};
  return {
    [cfiRange]: { id: "", text: "", selectionRange: cfiRange },
  };
};

const renderHighlights = (rendition: Rendition, highlights: Highlights, color: string): void => {
  if (!rendition) {
    logger.error("No rendition found to highlight a CFI range");
    return;
  }

  // EPUB.js `.each()` method doesn't work for some reason so we have to read a private property
  const annotationsObject: any = rendition.annotations;
  const annotations: { [key: string]: any } = annotationsObject._annotations;

  // Removing highlights that are not in the highlights object
  for (const renderedAnnotationKey in annotations) {
    if (renderedAnnotationKey.endsWith("highlight")) {
      const renderedCfiRange = convertFromAnnotationKey(renderedAnnotationKey);
      if (!(renderedCfiRange in highlights)) {
        try {
          rendition.annotations.remove(renderedCfiRange, "highlight");
        } catch {
          logger.error("Failed to remove a highlight", renderedCfiRange);
        }
      }
    }
  }

  // Adding highlights that are in the highlights object but not rendered
  for (const cfiRange in highlights) {
    const annotationKey = convertToAnnotationKey(cfiRange);
    if (annotationKey in annotations) continue; // Don't render the same highlight twice
    try {
      rendition.annotations.add("highlight", cfiRange, {}, undefined, "hl", {
        "fill": color,
        "fill-opacity": "0.2",
        "mix-blend-mode": "multiply",
      });
    } catch (e) {
      logger.error("Failed to highlight a CFI range", e);
    }
  }
};

type ReactReaderWrapperProps = {
  bookUrl: string;
  bookPosition?: BookPosition;
  onBookPositionChanged: (position: BookPosition) => void;
  highlights: Highlights;
  onCreateHighlight: (bookSelection: BookSelection) => Promise<void>;
  agentHighlight?: BookPosition;
  showTranslationRef: React.MutableRefObject<boolean>;
};

const ReactReaderWrapper = ({
  bookUrl,
  bookPosition,
  onBookPositionChanged,
  highlights,
  onCreateHighlight,
  agentHighlight,
  showTranslationRef,
}: ReactReaderWrapperProps): JSX.Element => {
  const [rendition, setRendition] = useState<Rendition>();
  const { setTOC } = useTOC();
  const selection = useRef<EpubJsSelection>(null);

  const locationChangeHandler = (epubPosition: string): void => {
    const isCfi = epubPosition.startsWith("epubcfi");
    if (!isCfi) onBookPositionChanged(epubPosition); // Ignore scrolling events, handle ToC clicks
  };

  // This effect allows jumping to a specific location in the book
  useEffect(() => {
    if (!rendition || typeof bookPosition !== "string") return;
    rendition.display(bookPosition);
  }, [rendition, bookPosition]);

  // This effect renders all highlights (user-provided and agent-provided)
  // TODO : drop agent highlight upon clicks and agentHighlight updates
  useEffect(() => {
    if (!rendition) return;
    setTimeout(() => {
      // const allHighlights = { ...highlights, ...wrapSingleCfiRange(agentHighlight) };
      const allHighlights = { ...wrapSingleCfiRange(agentHighlight) };
      renderHighlights(rendition, allHighlights, HIGHLIGHT_COLOR);
    }, EPUB_JS_DELAY);
  }, [rendition, highlights, agentHighlight]);

  // This effect sets up the mechanism to trigger onCreateHighlight when user selects text
  useEffect(() => {
    if (!rendition) return;

    function epubJsSelectionDelay(): Promise<void> {
      return new Promise((resolve) => setTimeout(resolve, EPUB_JS_DELAY));
    }

    function updateSelection(cfiRange: string, contents: Contents): void {
      selection.current = { cfiRange, contents };
    }

    function resetSelection(): void {
      selection.current = null;
    }

    async function triggerOnSelect(): Promise<void> {
      if (!rendition) return;
      await epubJsSelectionDelay();
      if (selection.current === null) return;
      const { cfiRange, contents } = selection.current;
      resetSelection();

      try {
        contents.window.getSelection()?.removeAllRanges();
        const highlightText = rendition.getRange(cfiRange)?.toString();
        if (!highlightText) throw new Error("Failed to get the highlight text");
        await onCreateHighlight({ selectionRange: cfiRange, text: highlightText });
      } catch (e) {
        // TODO : reflect the error in the reader UI
        logger.error("Something went wrong while saving the highlight", e);
      }
    }

    rendition.on("selected", updateSelection);
    rendition.on("mousedown", resetSelection);
    rendition.on("mouseup", triggerOnSelect);

    return () => {
      if (!rendition) {
        logger.error("No rendition found to remove event listeners");
      } else {
        rendition.off("selected", updateSelection);
        rendition.off("mousedown", resetSelection);
        rendition.off("mouseup", triggerOnSelect);
      }
    };
  }, [rendition, onCreateHighlight]);

  return (
    <ReactReader
      showToc={false}
      url={bookUrl}
      location={bookPosition}
      epubOptions={{
        flow: "scrolled",
        manager: "continuous",
        allowPopups: true, // Adds `allow-popups` to sandbox-attribute
        allowScriptedContent: true, // Adds `allow-scripts` to sandbox-attribute
      }}
      getRendition={(newRendition) => {
        setRendition(newRendition);
        newRendition.themes.default(REACT_READER_THEMES.DEFAULT);
        newRendition.themes.register("custom", REACT_READER_THEMES.CUSTOM);
        newRendition.themes.select("custom");
      }}
      showToc={false} // Using custom TOC renderer
      tocChanged={setTOC} // Correct interface is react-reader IToc, but it's missing attributes so we use TOCItem[]
      readerStyles={{
        ...ReactReaderStyle,
        arrow: {
          ...ReactReaderStyle.arrow,
          display: "none",
        },
      }}
      locationChanged={(newPosition: string) => {
        locationChangeHandler(newPosition);
        if (typeof newPosition !== "string") {
          logger.warn(`Variable newPosition is not a string: ${newPosition}`);
        }
        // onCfiPositionChanged(epubCFI); // TODO : seems that removing it doesn't break anything
        // locationChanged: a function that receives the current location while user is reading.
        // This function is called everytime the page changes, and also when it first renders.
        // highlight interaction logic
        const iframes = document.getElementsByTagName("iframe");
        const iframesArr = Array.prototype.slice.call(iframes);
        const epubjsView = iframesArr.filter((iframe) =>
          iframe.id.includes("epubjs-view"),
        )[0];
        const insideDocument = epubjsView.contentDocument;

        const ps = insideDocument.getElementsByTagName("p");
        for (let i = 0; i < ps.length; i++) {
          ps[i].style.cursor = "pointer";
          ps[i].onmouseenter = function () {
            if (!showTranslationRef.current) {
              return;
            }
            ps[i].id = i;
            ps[i].classList.add("paragraph-wrapper");
            let text = "";
            for (let item of ps[i].children) {
              if (item.tagName === "SPAN") {
                item.style.background = "rgba(77, 137, 255, 30%)";
                text += item.innerHTML;
              }
              if (
                item.className === "interaction-div" ||
                item.className === "generator-button"
              ) {
                item.style.display = "flex";
                // on mouse enter, show the interaction div?
                item.hidden = true;
              }
            }
          };
          ps[i].onmouseleave = function () {
            if (!showTranslationRef.current) {
              return;
            }
            ps[i].id = i;
            let text = "";
            let keepItOn = false;
            for (let item of ps[i].children) {
              if (item.tagName === "DIV") {
                for (let button of item.children) {
                  if (
                    button.classList.contains("interactionButtonHighlight") &
                    (button.level !== undefined)
                  ) {
                    keepItOn = true;
                    return;
                  }
                }
              }
            }
            for (let item of ps[i].children) {
              if (item.tagName === "SPAN") {
                item.style.background = "none";
                text += item.innerHTML;
              }
              if (
                item.className === "interaction-div" ||
                item.className === "generator-button"
              ) {
                item.style.display = "none";
                item.hidden = true;
              }
            }
          };
          let hasInteractionDiv = false;
          for (let item of ps[i].children) {
            if (item.className === "interaction-div") {
              hasInteractionDiv = true;
            }
          }

          if (!hasInteractionDiv) {
            const tooltip = document.createElement("div");
            tooltip.setAttribute("class", "interaction-div");

            // removing html default styles
            tooltip.style.border = "none";
            tooltip.style.color = "none";
            tooltip.style.backgroundColor = "none";
            tooltip.hidden = true;

            const button3 = document.createElement("button");
            button3.innerHTML = "Original";
            button3.classList.add("generator-button");
            tooltip.appendChild(button3);

            function setRightmost(button) {
              if (button) {
                button.classList.remove("generator-button");
                button.classList.add("generator-button-rightmost");
              }
            }

            // Get levels to build buttons for from the spans of the <p> el
            const levels = Array.from(ps[i].children)
              .filter((child) => child.getAttribute("level") !== null)
              .filter((child) => child.innerHTML.length > 0)
              .map((child) => child.getAttribute("level"));

            function getFixedButtonHTML(buttonHTML) {
              const spellingFixes = {
                "9th": "9th Grade",
                "3rd": "3rd Grade",
                college: "College",
              };
              return buttonHTML in spellingFixes
                ? spellingFixes[buttonHTML]
                : buttonHTML;
            }

            function buildButton(level) {
              const buttonElem = document.createElement("button");
              buttonElem.innerHTML = getFixedButtonHTML(level);
              buttonElem.level = level;
              buttonElem.classList.add("generator-button");
              const buttonSvg = makeSvg();
              buttonElem.appendChild(buttonSvg);
              tooltip.appendChild(buttonElem);
              return buttonElem;
            }

            const buttons = [];
            levels.forEach((level) => {
              buttons.push(buildButton(level));
            });
            // Set the last button as right most
            setRightmost(buttons[buttons.length - 1]);

            ps[i].insertBefore(tooltip, ps[i].firstChild);
          }
        }
      }}
    />
  );
};

const MemoizedReactReaderWrapper = React.memo(ReactReaderWrapper, (prevProps, nextProps) => {
  // TODO : super dirty hack to prevent jumps to start of the book
  if (nextProps.bookPosition === 0) return true;

  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});

export default MemoizedReactReaderWrapper;

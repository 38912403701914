import React from "react";
import { FaSave } from "react-icons/fa";
import { ReloadIcon } from "@radix-ui/react-icons";

import ModelSelect from "./ModelSelect";
import MessageArea from "./MessageArea";
import MessageInput from "./MessageInput";

import { BookPosition } from "../../../types";
import { CHAT_MODEL_META } from "../../../constants";
import { useConversation } from "../../../context/ConversationContext";
import { useConversations } from "../../../hooks/useConversations";

type ConversationProps = {
  onSourceClick: (location: BookPosition) => void;
};

const Conversation = ({ onSourceClick }: ConversationProps): JSX.Element => {
  const { onCreateConversation } = useConversations();
  const { onClearConversation, conversationId, selectedModel, messages } = useConversation();

  return (
    <div className="flex flex-col h-full p-5">
      <div className="flex justify-between">
        <ModelSelect modelList={Object.values(CHAT_MODEL_META)} />
      </div>
      {/* <div className="flex gap-1">
        <button className="text-gray-400 pl-3" onClick={onClearConversation}>
          <ReloadIcon className="w-5 h-5" />
        </button>
        <button
          className="text-gray-400 pl-3"
          onClick={() =>
            onCreateConversation({
              conversationId: conversationId,
              chatModelSlug: selectedModel.slug,
              messages: messages,
            })
          }
        >
          <FaSave size={20} />
        </button>
      </div> */}
      <MessageArea onSourceClick={onSourceClick} />
      <MessageInput />
    </div>
  );
};

export default Conversation;

import React from "react";
import { Childful } from "../../types";

type BasicHeaderProps = Partial<Childful> & {
  title: string;
};

const BasicHeader = ({ title, children }: BasicHeaderProps): JSX.Element => (
  <div className="flex sticky top-0 bg-white items-center justify-between p-3 border-b border-b-gray-100">
    <h2 className="text-xl font-bold m-0">{title}</h2>
    {children}
  </div>
);

export default BasicHeader;

import { Highlights } from "../reader/types";
import { BookSelection, Highlight } from "../reader/types";
import { ApiGetRequest, ApiPostRequest, ApiDeleteRequest } from "../hooks/useApiCaller";

type SavedHighlight = {
  id: string;
};

export const saveHighlight = async (
  postFn: ApiPostRequest<SavedHighlight>,
  bookId: string,
  bookSelection: BookSelection,
): Promise<Highlight> => {
  const { id } = await postFn(`/api/highlights?book-id=${bookId}`, {
    "book_position": bookSelection.selectionRange,
    "text": bookSelection.text,
  });
  return { id, ...bookSelection };
};

type ServerHighlight = {
  id: string;
  book_position: string;
  text: string;
};

export const getHighlights = async (
  getFn: ApiGetRequest<ServerHighlight[]>,
  bookId: string,
): Promise<Highlights> => {
  const response = await getFn(`/api/highlights?book-id=${bookId}`);
  return response.reduce((acc: Highlights, highlight: ServerHighlight) => {
    acc[highlight.book_position] = {
      id: highlight["id"],
      selectionRange: highlight["book_position"], // TODO : rename it on the backend because of PDFs
      text: highlight["text"] ?? "---missing text---",
    };
    return acc;
  }, {});
};

export const deleteHighlight = async (
  deleteFn: ApiDeleteRequest,
  highlightId: string,
): Promise<void> => {
  return await deleteFn(`/api/highlights/${highlightId}`);
};

import { useState, useEffect } from "react";

// TODO@xenohunter : use Tailwind prefixes instead of this hook

enum DEVICE_TYPES {
  MOBILE = "mobile",
  TABLET = "tablet",
  DESKTOP = "desktop",
};

type WindowSizeInfo = {
  deviceType: DEVICE_TYPES;
  isMobile: boolean;
  isDesktop: boolean;
};

export const useWindowSize = (): WindowSizeInfo => {
  const [deviceType, setDeviceSize] = useState(DEVICE_TYPES.DESKTOP);

  useEffect(() => {
    const handleResize = (): void => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 768) {
        setDeviceSize(DEVICE_TYPES.MOBILE);
      } else if (windowWidth < 1024) {
        setDeviceSize(DEVICE_TYPES.TABLET);
      } else {
        setDeviceSize(DEVICE_TYPES.DESKTOP);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    deviceType,
    isMobile: deviceType === DEVICE_TYPES.MOBILE,
    isDesktop: deviceType === DEVICE_TYPES.DESKTOP,
  };
};

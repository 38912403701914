import React, { useEffect, useState } from "react";

import { Book, BookPosition } from "../../../types";
import { logger } from "../../../tools/logger";
import { BOOK_FORMATS } from "../../../reader/BookReader";
import { timestampToClockTime } from "../../utils";
import { Message, SENDERS } from "../../../reader/types";

const EPUB_SOURCE_REGEX = /(?=epubcfi\()/g;

/* This function is here because if an array of sources is passed from the context,
 * the message (most probably) gets added instead of updated (so it'll be displayed
 * many times in the chat as the model response stream is fetched chunk by chunk)
 *
 * TODO : decouple the current message string from the sources list
 * TODO : move source parsing logic to the context file
 */
const parseSources = (sources: string, extension: BOOK_FORMATS): string[] | number[] => {
  if (extension === BOOK_FORMATS.PDF) {
    return sources.split(",").map((s) => parseInt(s.trim()));
  } else if (extension === BOOK_FORMATS.EPUB) {
    return sources
      .split(EPUB_SOURCE_REGEX)
      .filter((source) => source)
      .map((source) => source.replace(/,\s*$/, ""));
  } else {
    throw new Error(`Unsupported file extension: ${extension}`);
  }
};

// TODO : relocate this too when you decouple the message string from the sources list
const retrieveSources = (sources: Message["sources"], book: Book): string[] | number[] => {
  return sources ? parseSources(sources, book.format) : [];
};

type MessageItemProps = {
  index: number;
  message: Message;
  modelName: string;
  book: Book;
  onSourceClick: (location: BookPosition) => void;
  menuItems?: string[];
  handleUserMessage: (message?: string) => void;
};

const MessageItem = ({
  index,
  message,
  modelName,
  book,
  onSourceClick,
  menuItems,
  handleUserMessage,
}: MessageItemProps): JSX.Element => {
  const isDefaultMessage = index === 0 && message.sender === SENDERS.AI;
  const isUserMessage = message.sender === SENDERS.USER;

  const [showMenuItems, setShowMenuItems] = useState(false);

  const handleClick = (source: string | number): void => {
    logger.log(`setting location at ${source}`);
    onSourceClick(source);
  };

  const showSources = book.hasChatSources && message.sources && !isDefaultMessage && !isUserMessage;
  const parsedSources = showSources ? retrieveSources(message.sources, book) : [];

  // Set up an effect that runs when 'menuItems' changes or when the message text changes to show the menu items
  // after a delay. If depends on message.text because we only want to show the menu items after the complete message
  // text has been received.
  useEffect(() => {
    const MENU_ITEMS_DELAY_MS = 300;

    // If there are menu items, set up a delay to show them
    if (menuItems && menuItems.length > 0 && message.isComplete) {
      const timer = setTimeout(() => {
        setShowMenuItems(true);
      }, MENU_ITEMS_DELAY_MS);

      // Make sure to clear the timer if the component is unmounted
      return () => clearTimeout(timer);
    } else {
      // If there are no menu items, hide them immediately
      setShowMenuItems(false);
    }
  }, [menuItems, message.isComplete]);

  return (
    <div className="text-left text-sm">
      <strong className="mr-1">{isUserMessage ? "You" : "Agent"}</strong>
      {/* <span className="text-gray-500 text-xs">
        {timestampToClockTime(message.timestamp)}
      </span> */}
      <p className="mt-1">{message.text}</p>
      {showSources && parsedSources.length && (
        <div className={"flex items-center"}>
          <strong>Sources: </strong>
          {parsedSources.map((source, index) => (
            <div
              key={index}
              className="ml-1 text-gray-400 hover:text-blue-700 hover:underline focus:outline-none cursor-pointer"
              onClick={() => handleClick(source)}
            >
              {`[${index + 1}]`}
            </div>
          ))}
        </div>
      )}
      {/* {showMenuItems && menuItems && (
        <div className={"flex flex-wrap mt-2"}>
          {menuItems.map((item, index) => (
            <div
              key={index}
              className="mr-2 mb-2 px-3 py-1 text-gray-400 bg-white border border-gray-300 rounded-full hover:text-blue-700 hover:border-blue-700 cursor-pointer"
              onClick={() => {
                handleUserMessage(item);
              }}
            >
              {item}
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
};

export default MessageItem;

import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth } from "@clerk/clerk-react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Book, ChatModelMeta, Childful } from "../types";
import { CHAT_MODEL_META } from "../constants";
import { logger } from "../tools/logger";
import { interactWithAgentById, fetchChatStream, clearMemoryByConversationId } from "../api/agents";
import { logConversation } from "../api/conversationsLogs";
import { useApiCaller } from "../hooks/useApiCaller";
import { Conversation, Message, SENDERS } from "../reader/types";
import { convertToMessages } from "../reader/convertTypes";
import { generateConversationHistory } from "../components/utils";
import { config } from "../config";
import { useAnalytics } from "../analytics/useAnalytics";

// Typing indicator is shown for 1 second, duration derived by playing around
// Best to keep it short to have playful UX while not being annoying
const TYPING_INDICATOR_TIMEOUT = 1000;

const initializeIntro = (model: ChatModelMeta, book: Book): string => {
  const bookAuthors = book.authors.join(", ") || "Unknown author";
  return model.intro
    .replace("{author_name}", bookAuthors)
    .replace("{book_title}", book.title);
};

type ConversationContextType = {
  book: Book;
  messages: Message[];
  inputMessage: string;
  handleInputChange: (e: React.ChangeEvent<HTMLDivElement>) => void;
  handleUserMessage: () => Promise<void>;
  isTyping: boolean;
  handleModelChange: (model: ChatModelMeta) => void;
  selectedModel: ChatModelMeta;
  showDelayIndicator: boolean;
  conversationId: string;
  handleLoadPreviousConversation: (conversation: Conversation) => void;
  onClearConversation: () => void;
};

const ConversationContext = createContext<ConversationContextType>(undefined!);

export const useConversation = (): ConversationContextType => {
  return useContext(ConversationContext);
};

type ConversationProviderProps = Childful & {
  book: Book;
};

export const ConversationProvider = ({ children, book }: ConversationProviderProps): JSX.Element => {
  const { getToken } = useAuth();

  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState<string>("");
  const [showTypingIndicator, setShowTypingIndicator] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<ChatModelMeta>(Object.values(CHAT_MODEL_META)[0]);
  const [conversationId, setConversationId] = useState<string>(uuidv4());
  const { post } = useApiCaller();
  const [timerElapsed, setTimerElapsed] = useState<boolean>(false);
  const { logOnUserMessageSent, logOnConversationCleared, logOnConversationReopened } = useAnalytics();

  // Whether the backend is missing conversation context and we need to include it in the next request.
  // This happens when the user changes the model or loads a previous conversation.
  const [sendContext, setSendContext] = useState<boolean>(false);

  // Initialize the conversation with a greeting from the AI
  useEffect(() => {
    if (messages.length === 0) {
      setShowTypingIndicator(true);
      const timeoutId = setTimeout(() => {
        setShowTypingIndicator(false);
        setMessages([
          {
            id: uuidv4(),
            sender: SENDERS.AI,
            text: initializeIntro(selectedModel, book),
            timestamp: new Date(),
            isComplete: true,
          },
        ]);
      }, TYPING_INDICATOR_TIMEOUT);
      return () => clearTimeout(timeoutId);
    }
  }, [book, messages, selectedModel]);

  const onClearConversation = async (): Promise<void> => {
    try {
      await clearMemoryByConversationId(conversationId, await getToken());
      setMessages([]);
      logOnConversationCleared(conversationId);
    } catch (error) {
      logger.error(`Error clearing memory for conversation ${conversationId}`);
      toast.error("Something went wrong - conversation not cleared.");
    }
  };



  const handleLoadPreviousConversation = async (conversation: Conversation): Promise<void> => {
    const { messages, chatModelSlug, id, bookId } = conversation;

    // Check if the conversation is for the current book
    if (bookId !== book.slug) {
      toast.error("Something went wrong.");
      logger.error(`Error loading previous conversation ${id}, it's book slug ${bookId} doesn't match the open book ${book.slug}`);
      return;
    }
    setConversationId(id);
    setMessages(convertToMessages(messages));

    // Check if model is still available, if not, switch to default
    const model = CHAT_MODEL_META[chatModelSlug];
    if (model) {
      setSelectedModel(model);
    } else {
      const defaultModel = Object.values(CHAT_MODEL_META)[0];
      toast.warning(`Previous chat model is not available anymore, switching to ${defaultModel.name}`);
      logger.warn(`Model ${chatModelSlug} is not available anymore, switching to ${defaultModel.slug}`);
    }


    // We clear the memory of the previous conversation, so that the AI doesn't get confused.
    // In most cases, the conversation is already cleared as it is stored in RAM, but we do it again just to be sure.
    try {
      await clearMemoryByConversationId(id, await getToken());
    } catch (error) {
      logger.error(`Error clearing memory for conversation ${id}`);
    }

    // As we are loading a previous conversation, we need to send the context in the next request
    setSendContext(true);

    logOnConversationReopened(conversation);
  };

  const handleModelChange = (model: ChatModelMeta): void => {
    if (model.name !== selectedModel.name) {
      setMessages([]); // TODO : keep current conversations with each model in the state
      setSelectedModel(model);
      setConversationId(uuidv4());
    }
  };

  // TODO: This whole function is a mess. Refactor it.
  // This function gets called both when a user sends a message and when he selects one of the menu suggestions.
  // If the user sends a message, the inputMessage gets used. If the user selects a suggestion, the message
  // gets passed as an argument.
  const handleUserMessage = async (message?: string): Promise<void> => {
    let userMessage = message || inputMessage;
    if (userMessage.trim() !== "") {

      const userMessageModel: Message = {
        id: uuidv4(),
        sender: SENDERS.USER,
        text: userMessage,
        isComplete: true,
        timestamp: new Date(),
      };

      addMessage(userMessageModel);

      logOnUserMessageSent(userMessageModel, message !== undefined);

      // If the user sends a message, we add it to the conversation history.
      // Note: this needs to happen after we addMessage() the user messsage
      // or else it's shown as part of the user message in the UI.
      if (sendContext) {
        userMessage = generateConversationHistory(messages) + userMessage;
        setSendContext(false);
      }

      // Only reset the input if the message was sent by the user
      if (message === undefined) {
        setInputMessage(""); // TODO : probably block input until the AI finishes typing?
      }

      setShowTypingIndicator(true);

      let timerId: NodeJS.Timeout;
      setTimerElapsed(false);

      timerId = setTimeout(() => {
        setTimerElapsed(true);
      }, selectedModel.warningDelayMs);



      try {


        const messageId = uuidv4();
        if (selectedModel.isStreaming) {
          let message = "";
          let metaData: ({ sources: string | undefined }) = { sources: undefined };

          // TODO : suggestion to move first-chunk-handling logic to the API layer
          await fetchChatStream(
            userMessage,
            conversationId,
            selectedModel.slug,
            book.slug,
            (chunk) => {
              // The first chunk of the stream includes meta data about the sources.
              // In the future, more data might be supplied in the first chunk.
              if (message === "" && metaData.sources === undefined) {
                metaData = JSON.parse(chunk);
                setShowTypingIndicator(false);
              } else {
                // Add the new chunk to the existing message.
                message += chunk;
              }
              addMessage({
                id: messageId,
                sender: SENDERS.AI,
                text: message,
                isComplete: false,
                sources: metaData?.sources,
                timestamp: new Date(),
              });
            },
            await getToken(),

          );

          // Mark the message as complete.
          addMessage({
            id: messageId,
            sender: SENDERS.AI,
            text: message,
            isComplete: true,
            sources: metaData?.sources,
            timestamp: new Date(),
          });


          // TODO: Remove duplicate code between this and the non-streaming case
          const conversation = {
            question: userMessage,
            response: message,
            conversationId: conversationId,
            bookId: book.slug,
          };

          logConversation(post, selectedModel.slug, conversation, config.ENVIRONMENT);
        } else {
          const response = await interactWithAgentById(
            userMessage,
            selectedModel.slug,
            conversationId,
            book.slug,
            await getToken(),
          );
          addMessage(
            {
              id: messageId,
              sender: SENDERS.AI,
              text: response.answer,
              isComplete: true,
              sources: response.sources,
              timestamp: new Date(),
            });

          const conversation = {
            question: userMessage,
            response: response.answer,
            conversationId: conversationId,
            bookId: book.slug,
          };

          logConversation(post, selectedModel.slug, conversation, config.ENVIRONMENT);
        }
      } catch (error: any) {
        logger.error(error);
        addMessage(
          {
            id: uuidv4(),
            sender: SENDERS.AI,
            text: selectedModel.error,
            isComplete: true,
            timestamp: new Date(),
          }
        );
      } finally {
        setShowTypingIndicator(false);
        clearTimeout(timerId);
        setTimerElapsed(false);
      }
    }
  };

  // Updates an existing message with new text or adds a new message to the list of messages
  // TODO : suggestion to keep the currently streamed message in the state and update it directly
  const addMessage = ({ id, sender, text, isComplete, sources = undefined, timestamp }: Message): void => {
    setMessages((prevMessages) => {
      // Check if there's an existing message with the given ID
      // This can happen if the message is supplied by a stream in chunks and build up over time
      const existingMessageIndex = prevMessages.findIndex((msg) => msg.id === id);

      if (existingMessageIndex !== -1) {
        const updatedMessages = structuredClone(prevMessages);
        updatedMessages[existingMessageIndex].text = text;
        updatedMessages[existingMessageIndex].isComplete = isComplete;
        return updatedMessages;
      } else {
        return [
          ...prevMessages,
          { id, sender, text, sources, timestamp: timestamp, isComplete },
        ];
      }
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLDivElement>): void => {
    setInputMessage(event.target.innerText);
  };

  return (
    <ConversationContext.Provider
      value={{
        book,
        messages,
        inputMessage,
        handleInputChange,
        handleUserMessage,
        isTyping: showTypingIndicator,
        handleModelChange,
        selectedModel,
        showDelayIndicator: timerElapsed,
        conversationId: conversationId,
        handleLoadPreviousConversation,
        onClearConversation,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};

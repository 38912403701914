import { useAppDispatch, useAppSelector } from "../state/hooks";
import { jumpTo, selectBook, selectBookPosition } from "../state/readerSlice";
import { Book, BookPosition } from "../types";

export const useBook = (): {
  book: Book | undefined;
  bookPosition: BookPosition | undefined;
  setBookPosition: (bookPosition: BookPosition) => void;
} => {
  const dispatch = useAppDispatch();
  const book = useAppSelector(selectBook);
  const bookPosition = useAppSelector(selectBookPosition);

  const setBookPosition = (bookPosition: BookPosition): void => {
    dispatch(jumpTo(bookPosition));
  };

  return {
    book,
    bookPosition,
    setBookPosition,
  };
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { Book, BookPosition } from "../types";
import { Highlight, Highlights } from "../reader/types";

import { fetchHighlightsAsync, addHighlightAsync, removeHighlightAsync } from "./readerThunks";

type BookPayload = {
  book: Book;
};

type BookState = {
  book?: Book;
  bookPosition?: BookPosition;
  userHighlights: Highlights;
  agentHighlight?: BookPosition;
};

const bookState: BookState = {
  book: undefined,
  bookPosition: undefined,
  userHighlights: {},
  agentHighlight: undefined,
};

const bookSlice = createSlice({
  name: "reader",
  initialState: bookState,
  reducers: {
    openBook: (state, action: PayloadAction<BookPayload>) => {
      state.book = action.payload.book;
      state.userHighlights = {};
      state.agentHighlight = undefined;
    },
    jumpTo: (state, action: PayloadAction<BookPosition>) => {
      state.bookPosition = action.payload;
    },
    addHighlight: (state, action: PayloadAction<Highlight>) => {
      const { selectionRange } = action.payload;
      state.userHighlights[selectionRange] = action.payload;
    },
    removeHighlight: (state, action: PayloadAction<Highlight>) => {
      delete state.userHighlights[action.payload.selectionRange];
    },
    setAgentHighlight: (state, action: PayloadAction<BookPosition>) => {
      state.agentHighlight = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHighlightsAsync.fulfilled, (state, action) => {
      for (const highlight of Object.values(action.payload)) {
        const { selectionRange } = highlight;
        state.userHighlights[selectionRange] = highlight;
      }
    });
    builder.addCase(addHighlightAsync.fulfilled, (state, action) => {
      const { selectionRange } = action.payload;
      state.userHighlights[selectionRange] = action.payload;
    });
    builder.addCase(removeHighlightAsync.fulfilled, (state, action) => {
      delete state.userHighlights[action.payload.selectionRange];
    });
  },
});

export const { openBook, jumpTo, addHighlight, removeHighlight, setAgentHighlight } = bookSlice.actions;

export const selectBook = (state: RootState): Book | undefined => state.reader.book;
export const selectBookPosition = (state: RootState): BookPosition | undefined => state.reader.bookPosition;
export const selectUserHighlights = (state: RootState): Highlights => state.reader.userHighlights;
export const selectAgentHighlight = (state: RootState): BookPosition | undefined => state.reader.agentHighlight;

export default bookSlice.reducer;

import React from "react";

import styles from "./Conversation.module.css";

type TypingIndicatorProps = {
  modelName: string;
  showDelayIndicator: boolean;
};

const TypingIndicator = ({ modelName, showDelayIndicator }: TypingIndicatorProps): JSX.Element => (
  <div className="text-left text-sm">
    <strong className="mr-1">Agent</strong>
    <div className="flex items-center mt-1 space-x-2">
      {showDelayIndicator ? "OpenAI is taking longer than expected ..." : <div><span
        role="img"
        aria-label="Expanding Cloud"
        className={`text-lg ${styles["expanding-emoji"]}`}
      >
        💭
      </span>
      <span
        role="img"
        aria-label="Thinking Face"
        className={`text-lg ${styles["expanding-emoji"]}`}
      >
          ✍️
      </span>
      </div>}

    </div>
  </div>
);

export default TypingIndicator;

import React from "react";
import { Childful } from "../../types";

export enum LAYOUT_TYPE {
  COLUMNS = "columns",
  ROWS = "rows",
  CENTERED = "centered",
}

type BasicLayoutProps = Childful & {
  type: LAYOUT_TYPE;
  isLoading?: boolean;
};

// TODO : change this to something conceptually meaningful
const CLASS_NAMES = {
  [LAYOUT_TYPE.COLUMNS]: "flex flex-col h-screen",
  [LAYOUT_TYPE.ROWS]: "flex flex-row h-screen",
  [LAYOUT_TYPE.CENTERED]: "flex h-screen w-full items-center justify-center",
};

/**
 * A basic layout component that should be used as the root component in a page (see `/src/pages`)
 * Stability: Experimental
 */
const BasicLayout = ({ children, type, isLoading }: BasicLayoutProps): JSX.Element => {
  if (isLoading) {
    return <div className={CLASS_NAMES[LAYOUT_TYPE.CENTERED]}>Loading...</div>;
  } else {
    return (
      <div className={CLASS_NAMES[type]}>
        {children}
      </div>
    );
  }
};

BasicLayout.defaultProps = {
  isLoading: false,
};

export default BasicLayout;

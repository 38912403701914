import React from "react";
import clsx from "clsx";
import { ChatModelMeta } from "../../../types";
import { useConversation } from "../../../context/ConversationContext";

const CLASS_NAME = clsx(
  "inline-flex justify-center w-full",
  "rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700",
  "hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
);

type ModelSelectProps = {
  modelList: ChatModelMeta[];
};

const ModelSelect = ({ modelList }: ModelSelectProps): JSX.Element => {
  const {
    handleModelChange,
    selectedModel,
  } = useConversation();

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const newModel = modelList.find((model) => model.name === e.target.value);
    handleModelChange(newModel!);
  };

  // TODO : create a select component that can be used in multiple places
  return (
    <div className="relative inline-block text-left">
      <select
        className={CLASS_NAME}
        value={selectedModel.name}
        onChange={handleSelectChange}
      >
        {modelList.map((model) => (
          <option key={model.name} value={model.name}>
            {model.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ModelSelect;

import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { BookSelection, Highlight, Highlights } from "../reader/types";

import { ApiCaller } from "../hooks/useApiCaller";
import { getHighlights, saveHighlight, deleteHighlight } from "../api/highlights";
import { logger } from "../tools/logger";

enum ERRORS {
  NO_BOOK_OPEN = "No book open",
  FAILURE = "Connection or server failure",
}

type ThunkArgs = {
  apiCaller: ApiCaller;
};

export const fetchHighlightsAsync = createAsyncThunk<Highlights, ThunkArgs>(
  "reader/fetchHighlights",
  async ({ apiCaller }: ThunkArgs, { getState, rejectWithValue }) => {
    // TODO : make a centralized type for thunkApi and use it instead of using `as RootState`
    const state = getState() as RootState;
    if (!state.reader.book) {
      logger.error("Tried to fetch highlights without a book open");
      return rejectWithValue(ERRORS.NO_BOOK_OPEN);
    } else {
      try {
        // TODO : check how `slug` and not `id` works with the backend
        const bookId = state.reader.book.slug;
        return await getHighlights(apiCaller.get, bookId);
      } catch (e) {
        logger.error("Error while fetching highlights", e);
        return rejectWithValue(ERRORS.FAILURE);
      }
    }
  },
);

type AddHighlightArgs = ThunkArgs & {
  bookSelection: BookSelection;
};

export const addHighlightAsync = createAsyncThunk<Highlight, AddHighlightArgs>(
  "reader/addHighlight",
  async ({ apiCaller, bookSelection }: AddHighlightArgs, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    if (!state.reader.book) {
      logger.error("Tried to add highlight without a book open");
      return rejectWithValue(ERRORS.NO_BOOK_OPEN);
    } else {
      try {
        // TODO : check how `slug` and not `id` works with the backend
        const bookId = state.reader.book.slug;
        return await saveHighlight(apiCaller.post, bookId, bookSelection);
      } catch (e) {
        logger.error("Error while adding highlight", e);
        return rejectWithValue(ERRORS.FAILURE);
      }
    }
  },
);

type RemoveHighlightArgs = ThunkArgs & {
  highlight: Highlight;
};

export const removeHighlightAsync = createAsyncThunk<Highlight, RemoveHighlightArgs>(
  "reader/removeHighlight",
  async ({ apiCaller, highlight }: RemoveHighlightArgs, { rejectWithValue }) => {
    try {
      await deleteHighlight(apiCaller.del, highlight.id);
      return highlight;
    } catch (e) {
      logger.error("Error while deleting highlight", e);
      return rejectWithValue(ERRORS.FAILURE);
    }
  },
);

import React, { useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { usePostHog } from "posthog-js/react";
import { Conversation, Message, SENDERS } from "../reader/types";
import { Book } from "../types";

type AnalyticsHookType = {
  logOnUserMessageSent: (message: Message, isPrefilledPrompt: boolean) => void;
  logOnBookOpened: (book: Book) => void;
  logOnConversationSaved: (conversation: Conversation) => void;
  logOnConversationDeleted: (conversation: Conversation) => void;
  logOnConversationReopened: (conversation: Conversation) => void;
  logOnConversationCleared: (conversationId: String) => void;
};


export const useAnalytics = (): AnalyticsHookType => {

  const posthog = usePostHog();

  const logOnUserMessageSent = (message: Message, isPrefilledPrompt: boolean): void => {
    if (message.sender !== SENDERS.USER) return;

    posthog.capture("Message Sent", {
      message: message,
      isPrefilledPrompt: isPrefilledPrompt,
    });
  };

  const logOnBookOpened = (book: Book): void => {
    posthog.capture("Book Opened", {
      book: book,
    });
  };

  const logOnConversationSaved = (conversation: Conversation): void => {
    posthog.capture("Conversation Saved", {
      conversation: conversation,
    });
  };

  const logOnConversationDeleted = (conversation: Conversation): void => {
    posthog.capture("Conversation Deleted", {
      conversation: conversation,
    });
  };

  const logOnConversationReopened = (conversation: Conversation): void => {
    posthog.capture("Conversation Reopened", {
      conversation: conversation,
    });
  };

  const logOnConversationCleared = (conversationId: String): void => {
    posthog.capture("Conversation Cleared", {
      conversationId: conversationId,
    });
  };


  return {
    logOnUserMessageSent,
    logOnBookOpened,
    logOnConversationSaved,
    logOnConversationDeleted,
    logOnConversationReopened,
    logOnConversationCleared,
  };


};

// TODO : use trackjs.com or something similar + add a more sophisticated logger

export const logger = {
  log: (message: string): void => {
    console.log(message);
  },
  warn: (message: string): void => {
    console.warn(message);
  },
  error: (...args: any[]): void => {
    console.error(...args);
  },
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TOCItem } from "../types";

type TOCState = {
  toc?: TOCItem[];
};

const initialState: TOCState = {
  toc: undefined,
};

const tocSlice = createSlice({
  name: "toc",
  initialState,
  reducers: {
    setTOC: (state, action: PayloadAction<TOCItem[]>) => {
      state.toc = action.payload;
    },
  },
});

export const { setTOC } = tocSlice.actions;

export const selectTOC = (state: RootState): TOCItem[] | undefined => state.toc.toc;

export default tocSlice.reducer;

import { ApiPostRequest } from "../hooks/useApiCaller";
import { ConversationLog } from "../reader/types";

export const logConversation = async (
  postFn: ApiPostRequest<ConversationLog>,
  agentId: string,
  conversation: ConversationLog,
  environment: string,
): Promise<ConversationLog> => {
  try {
    return await postFn(`/api/agents/${agentId}/conversations`, {
      "question": conversation.question,
      "response": conversation.response,
      "conversation_id": conversation.conversationId,
      "book_id": conversation.bookId,
      "environment": environment,
    });
  } catch (e) {
    console.error("Failed to log conversation:", e);
    throw e;
  }
};

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Conversation, Conversations, Message } from "../reader/types";
import { selectConversations } from "../state/chatSlice";
import { addConversationAsync, fetchConversationsAsync, removeConversationAsync, ERRORS } from "../state/chatThunks";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { selectBook } from "../state/readerSlice";
import { useApiCaller } from "./useApiCaller";
import { convertToConversationMessages } from "../reader/convertTypes";
import { useAnalytics } from "../analytics/useAnalytics";

type ConversationParams = {
  conversationId: string;
  chatModelSlug: string;
  messages: Message[];
};

type ConversationsHookType = {
  conversations: Conversations;
  onCreateConversation: (params: ConversationParams) => Promise<void>;
  onFetchConversations: () => Promise<void>;
  onDeleteConversation: (conversation: Conversation) => Promise<void>;
};

export const useConversations = (): ConversationsHookType => {
  const apiCaller = useApiCaller();
  const conversations = useAppSelector(selectConversations);
  const book = useAppSelector(selectBook);
  const { logOnConversationSaved, logOnConversationDeleted } = useAnalytics();

  const dispatch = useAppDispatch();

  const onCreateConversation = async ({ conversationId, chatModelSlug, messages }: ConversationParams): Promise<void> => {
    if (!book) return;
    const conversation: Conversation = {
      id: conversationId,
      bookId: book.slug,
      chatModelSlug: chatModelSlug,
      messages: convertToConversationMessages(messages),
    };
    try {
      await dispatch(addConversationAsync({ apiCaller, conversation })).then((result) => {
        if (result.type === "chat/addConversation/fulfilled") {
          toast.success("Conversation saved.");
        } else if (result.type === "chat/addConversation/rejected") {
          if (result.payload === ERRORS.NO_MESSAGES) {
            toast.error("Can't save empty conversation.");
          } else {
            toast.error("Something went wrong - conversation not saved.");
          }
        }
      });
      logOnConversationSaved(conversation);

    } catch (error) {
      toast("Conversation not saved");
    }
  };

  const onFetchConversations = async (): Promise<void> => {
    dispatch(fetchConversationsAsync({ apiCaller }));
  };

  const onDeleteConversation = async (conversation: Conversation): Promise<void> => {
    dispatch(removeConversationAsync({ apiCaller, conversation }));
    logOnConversationDeleted(conversation);
  };

  return {
    conversations,
    onCreateConversation,
    onFetchConversations,
    onDeleteConversation,
  };
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { Conversations, Conversation } from "../reader/types";
import { addConversationAsync, fetchConversationsAsync, removeConversationAsync } from "./chatThunks";

type ChatPayload = {
  conversations: Conversations;
};

export type ChatState = {
  conversations: Conversations;
};


const chatState: ChatState = {
  conversations: {},
};


const chatSlice = createSlice({
  name: "chat",
  initialState: chatState,
  reducers: {
    openConversations: (state, action: PayloadAction<ChatPayload>) => {
      state.conversations = action.payload.conversations;
    },
    addConversation: (state, action: PayloadAction<Conversation>) => {
      const { id } = action.payload;
      state.conversations[id] = action.payload;
    },
    removeConversation: (state, action: PayloadAction<Conversation>) => {
      delete state.conversations[action.payload.id];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConversationsAsync.fulfilled, (state, action) => {
      state.conversations = action.payload;
    });
    builder.addCase(addConversationAsync.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.conversations[id] = action.payload;
    });
    builder.addCase(removeConversationAsync.fulfilled, (state, action) => {
      delete state.conversations[action.payload.id];
    });
  },
});

export const { openConversations, addConversation, removeConversation } = chatSlice.actions;

export const selectChat = (state: RootState): ChatState => state.chat;

export const selectConversations = (state: RootState): Conversations => state.chat.conversations;

export default chatSlice.reducer;

import React, { useEffect } from "react";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { highlightPlugin, RenderHighlightsProps, RenderHighlightTargetProps } from "@react-pdf-viewer/highlight";
import { BookmarkPlugin } from "@react-pdf-viewer/bookmark";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";

import { Book, BookPosition } from "../../types";
import { BookSelection, Highlights } from "../types";
import { logger } from "../../tools/logger";
import { serializeHighlight, deserializeHighlight, getBoundingRectangle } from "./utils";

import HighlightRectangle from "./HighlightRectangle";
import HighlightButton from "./HighlightButton";

const PDF_JS_VERSION = "3.4.120"; // Has to be in accordance with the versions used by @react-pdf-viewer Worker
const WORKER_URL = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDF_JS_VERSION}/pdf.worker.min.js`;
const PRESERVE_LOCATION_MARK = "preserve-location";

type PdfReaderWrapperProps = {
  book: Book;
  bookPosition?: BookPosition;
  onBookPositionChanged: (position: BookPosition) => void;
  // highlights: Highlights;
  // onCreateHighlight: (bookSelection: BookSelection) => Promise<void>;
  bookmarkPluginInstance: BookmarkPlugin;
};

const PdfReaderWrapper = ({
  book,
  bookPosition,
  onBookPositionChanged,
  // highlights,
  // onCreateHighlight,
  bookmarkPluginInstance,
}: PdfReaderWrapperProps): JSX.Element => {
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  // const onHighlightAttempt = ({
  //   selectedText,
  //   highlightAreas,
  // }: RenderHighlightTargetProps): JSX.Element => {
  //   const selectionRegion = getBoundingRectangle(highlightAreas);
  //   return (
  //     <HighlightButton
  //       left={selectionRegion.left}
  //       width={selectionRegion.width}
  //       top={selectionRegion.top + selectionRegion.height}
  //       onClick={(): void => {
  //         // Assuming bookPosition won't change while the highlight is being saved (may become false)
  //         onBookPositionChanged(PRESERVE_LOCATION_MARK);
  //         onCreateHighlight({
  //           selectionRange: serializeHighlight(selectionRegion),
  //           text: selectedText,
  //         });
  //       }}
  //     />
  //   );
  // };

  // const renderAllHighlights = (props: RenderHighlightsProps): JSX.Element => {
  //   return (
  //     <>
  //       {Object.values(highlights)
  //          // TODO! : explicit BookPosition
  //         .map((highlight) => deserializeHighlight(highlight.selectionRange as string))
  //         .filter((area) => area.pageIndex === props.pageIndex)
  //         .map((area) => {
  //           return <HighlightRectangle
  //             key={JSON.stringify(area)}
  //             area={area}
  //             props={props}
  //           />;
  //         })}
  //     </>
  //   );
  // };

  // const highlightPluginInstance = highlightPlugin({
  //   renderHighlightTarget: onHighlightAttempt,
  //   renderHighlights: renderAllHighlights,
  // });

  // const { jumpToHighlightArea } = highlightPluginInstance;

  const logBookPositionError = (position?: BookPosition): void => {
    logger.error(`Invalid book position: ${position}`);
  };

  useEffect(() => {
    if (typeof bookPosition === "number") {
      jumpToPage(bookPosition);
    } else if (typeof bookPosition === "string") {
      if (bookPosition !== PRESERVE_LOCATION_MARK) {
        try {
          const area = deserializeHighlight(bookPosition);
          // jumpToHighlightArea(area);
        } catch (e) {
          logBookPositionError(bookPosition);
        }
      }
    } else {
      logBookPositionError(bookPosition);
    }
  // }, [bookPosition, jumpToPage, jumpToHighlightArea]);
  }, [bookPosition, jumpToPage]);

  return (
    <div className="relative w-full h-full overflow-auto">
      <div className="absolute h-full w-full">
        <Worker workerUrl={WORKER_URL}>
          <Viewer
            fileUrl={book.src}
            enableSmoothScroll={false} // This should stay false to prevent rendering of all scrolled pages
            plugins={[
              pageNavigationPluginInstance,
              // highlightPluginInstance,
              bookmarkPluginInstance,
            ]}
          />
        </Worker>
      </div>
    </div>
  );
};

const MemoizedPdfReaderWrapper = React.memo(PdfReaderWrapper, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});

export default MemoizedPdfReaderWrapper;

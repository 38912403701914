import { map, prop } from "ramda";

import { Book, BookPosition } from "../types";
import { BookData } from "../validation/newBookSchema";
import { BOOK_FORMATS } from "../reader/BookReader";
import { ApiGetRequest } from "../hooks/useApiCaller";
import { ApiPostRequest } from "../hooks/useApiCaller";
import { config } from "../config";

const SUPABASE_PUBLIC_ROOT = `https://${config.REACT_APP_SUPABASE_ID}.supabase.co/storage/v1/object/public`;
const BOOK_BUCKET = `${SUPABASE_PUBLIC_ROOT}/books`;
const COVER_BUCKET = `${SUPABASE_PUBLIC_ROOT}/covers`;

// TODO : validate BookResponse (check that e.g. custom_cover is not an empty string but is either a string or null)
type BookResponse = {
  "slug": string;
  "added_at": string;
  "uploader_id": string;
  "format": string;
  "title": string;
  "authors": { "name": string; }[];
  "categories": { "name": string; }[];
  "has_chat_interface": boolean;
  "has_chat_sources": boolean;
  "is_embedded": boolean;
  "is_public": boolean;
  "position"?: string;
  "custom_cover"?: string;
};

const processBook = (book: BookResponse): Book => {
  const coverFilename = book["custom_cover"] ?? `${book["slug"]}.png`;
  return {
    slug: book["slug"],
    addedAt: book["added_at"],
    uploaderId: book["uploader_id"],
    format: book["format"] as BOOK_FORMATS,
    src: `${BOOK_BUCKET}/${book["slug"]}.${book["format"]}`,
    cover: `${COVER_BUCKET}/${coverFilename}`,
    title: book["title"],
    authors: map(prop("name"), book["authors"]),
    categories: map(prop("name"), book["categories"]),
    hasChatInterface: book["has_chat_interface"],
    hasChatSources: book["has_chat_sources"],
    isEmbedded: book["is_embedded"],
    isPublic: book["is_public"],
    position: (book["position"] || 0) as BookPosition,
  };
};

export const addBook = async (
  postFn: ApiPostRequest<BookResponse>,
  bookData: BookData,
): Promise<Book> => {
  const newBook = await postFn("/api/books", bookData, {
    upload: true,
    uploadFilenames: ["book_file", "cover_file"],
  });
  return processBook(newBook);
};

export const getBooks = async (
  getFn: ApiGetRequest<BookResponse[]>,
): Promise<Book[]> => {
  const books = await getFn("/api/books");
  return books.map(processBook);
};

export const getBooksByUserId = async (
  getFn: ApiGetRequest<BookResponse[]>,
  userId: string
): Promise<Book[]> => {
  const books = await getFn(`/api/books?user_id=${userId}`);
  return books.map(processBook);
};

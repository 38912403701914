import { config } from "../config";
import { apiCall, METHODS, SessionToken } from "../hooks/useApiCaller";

type AgentSynchronousResponse = {
  question: string;
  answer: string;
  sources: string;
};

export const interactWithAgentById = (
  question: string,
  id: string,
  conversationId: string,
  bookId: string,
  sessionToken: SessionToken,
): Promise<AgentSynchronousResponse> => {
  if (question) {
    return apiCall(
      METHODS.POST,
      `/api/agents/${id}`,
      {
        "question": question,
        "book_id": bookId,
        "conversation_id": conversationId,
      },
      sessionToken,
    );
  } else {
    throw new Error("Question is empty");
  }
};

export const fetchChatStream = async (
  query: string,
  conversationId: string,
  modelId: string,
  bookId: string,
  onChunkReceived: (chunk: string) => void,
  sessionToken: SessionToken,
): Promise<void> => {
  const headers = {
    Accept: "text/event-stream",
    Authorization: `Bearer ${sessionToken}`,
    "Content-Type": "application/json",
  };

  const body = JSON.stringify({
    "question": query,
    "book_id": bookId,
    "conversation_id": conversationId,
  });

  let response: Response;
  try {
    response = await fetch(
      `${config.REACT_APP_BACKEND_URL}/api/agents/stream/${modelId}`,
      {
        method: "POST",
        headers: headers,
        body: body,
      },
    );
  } catch (error) {
    // Handle network errors or invalid HTTP responses
    throw new Error(`Fetch failed: ${error}`);
  }

  // Check if the HTTP status code indicates success
  if (!response.ok) {
    response.text().then((responseBody) => {
      throw new Error(`HTTP error: ${response.status} ${response.statusText}. URL: ${response.url}. Body: ${responseBody}`);
    });
  }

  if (!response.body) {
    throw new Error("Response body is null");
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder();

  const readStream = (result: ReadableStreamReadResult<Uint8Array>): Promise<void> => {
    if (result.done) {
      return Promise.resolve();
    }

    // At this point, we know that result.value is a Uint8Array
    const chunk = decoder.decode(result.value!);
    onChunkReceived(chunk);
    return reader.read().then(readStream);
  };

  // Handle any errors thrown by reader.read()
  return reader.read().then(readStream).catch((error) => {
    throw new Error(`Error reading stream: ${error}`);
  });
};

export const clearMemoryByConversationId = (
  conversationId: string,
  sessionToken: SessionToken,
): Promise<AgentSynchronousResponse> => {
  return apiCall(
    METHODS.DELETE,
    "/api/agents/clear",
    {
      "conversation_id": conversationId,
    },
    sessionToken,
  );
};

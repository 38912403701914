import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { Conversation, Conversations } from "../reader/types";
import { ApiCaller } from "../hooks/useApiCaller";
import { logger } from "../tools/logger";
import { deleteConversation, getConversations, saveConversation } from "../api/conversations";

export enum ERRORS {
  NO_BOOK_OPEN = "No book open",
  FAILURE = "Connection or server failure",
  NO_MESSAGES = "No messages",
}

type ThunkArgs = {
  apiCaller: ApiCaller;
};

export const fetchConversationsAsync = createAsyncThunk<Conversations, ThunkArgs>(
  "chat/fetchConversations",
  async ({ apiCaller }: ThunkArgs, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    if (!state.reader.book) {
      logger.error("Tried to fetch conversations without a book open");
      return rejectWithValue(ERRORS.NO_BOOK_OPEN);
    } else {
      try {
        const bookId = state.reader.book.slug;
        return await getConversations(apiCaller.get, bookId);
      } catch (e) {
        logger.error("Error while fetching conversations", e);
        return rejectWithValue(ERRORS.FAILURE);
      }
    }
  },
);

type AddConversationArgs = ThunkArgs & {
  conversation: Conversation;
};

export const addConversationAsync = createAsyncThunk<Conversation, AddConversationArgs>(
  "chat/addConversation",
  async ({ apiCaller, conversation }: AddConversationArgs, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    if (!state.reader.book) {
      logger.error("Tried to add conversation without a book open");
      return rejectWithValue(ERRORS.NO_BOOK_OPEN);
    }
    // The conversation must have at least two messages (AI intro + user message)
    if (conversation.messages.length < 2) {
      logger.error("Tried to add conversation without messages");
      return rejectWithValue(ERRORS.NO_MESSAGES);
    }
    else {
      try {
        return await saveConversation(apiCaller.post, conversation);
      } catch (e) {
        logger.error("Error while adding conversation", e);
        return rejectWithValue(ERRORS.FAILURE);
      }
    }
  },
);


type RemoveConversationArgs = ThunkArgs & {
  conversation: Conversation;
};

export const removeConversationAsync = createAsyncThunk<Conversation, RemoveConversationArgs>(
  "chat/removeConversation",
  async ({ apiCaller, conversation }: RemoveConversationArgs, { rejectWithValue }) => {
    try {
      await deleteConversation(apiCaller.del, conversation.id);
      return conversation;
    } catch (e) {
      logger.error("Error while deleting conversation", e);
      return rejectWithValue(ERRORS.FAILURE);
    }
  },
);

import React, { useEffect, useState } from "react";

import { BOOK_FORMATS } from "../../reader/BookReader";
import { useApiCaller } from "../../hooks/useApiCaller";
import { newBookSchema, BookData } from "../../validation/newBookSchema";
import { addBook } from "../../api/books";
import { getUserId } from "../../api/misc";
import { slugify, slugifyAndPack, splitAndFilter } from "./utils";

import BasicLayout, { LAYOUT_TYPE } from "../../components/layout/BasicLayout";
import BasicHeader from "../../components/layout/BasicHeader";

// TODO! : make a backend method that returns the model's fields and build the form dynamically
// TODO! : move form and inputs to the standard components directory + polish those

const fieldsOrder = Object.keys(newBookSchema.fields);

enum STATUS_MESSAGE_TYPE {
  PROMPT = "prompt",
  PROCESSING = "processing",
  SUCCESS = "success",
  ERROR = "error",
}

export type StatusMessage = {
  "type": STATUS_MESSAGE_TYPE;
  "text": string;
};

const PROMPT_STATUS_MESSAGE = {
  "type": STATUS_MESSAGE_TYPE.PROMPT,
  "text": "Please fill in the form and select a book file and a cover file",
};

const BACKGROUND_COLOR_CLASSES = {
  [STATUS_MESSAGE_TYPE.PROMPT]: "bg-gray-300",
  [STATUS_MESSAGE_TYPE.PROCESSING]: "bg-blue-300",
  [STATUS_MESSAGE_TYPE.SUCCESS]: "bg-green-300",
  [STATUS_MESSAGE_TYPE.ERROR]: "bg-red-300",
};

const LibraryManager = (): JSX.Element => {
  const { get, post } = useApiCaller();

  const [userId, setUserId] = useState<string>();
  const [title, setTitle] = useState<string>("");
  const [authors, setAuthors] = useState<string>("");
  const [categories, setCategories] = useState<string>("");
  const [format, setFormat] = useState<string>("");
  const [hasChat, setHasChat] = useState<boolean>(false);
  const [hasSources, setHasSources] = useState<boolean>(false);
  const [position, setPosition] = useState<string>("");
  const [customCover, setCustomCover] = useState<string>("");

  const [bookFile, setBookFile] = useState<File>();
  const [coverFile, setCoverFile] = useState<File>();

  const [bookData, setBookData] = useState<BookData>();
  const [statusMessage, setStatusMessage] = useState<StatusMessage>(PROMPT_STATUS_MESSAGE);
  const [validationMessage, setValidationMessage] = useState<string>();

  const updateBookFile = (file: File | undefined): void => {
    if (!file) {
      setBookFile(undefined);
      setFormat("");
      setValidationMessage("Please select a file");
    } else {
      const format = file?.name.split(".").pop()?.toLowerCase();
      if (format && Object.values(BOOK_FORMATS).includes(format as BOOK_FORMATS)) {
        setBookFile(file);
        setFormat(format);
      } else {
        setBookFile(undefined);
        setFormat("");
        setValidationMessage("Invalid file format, please select an EPUB or PDF file");
      }
    }
  };

  const onSaveBook = async (): Promise<void> => {
    if (!bookData) {
      return;
    } else {
      try {
        setStatusMessage({
          "type": STATUS_MESSAGE_TYPE.PROCESSING,
          "text": "Adding book...",
        });

        await addBook(post, bookData);
        setBookData(undefined);

        setBookFile(undefined);
        setCoverFile(undefined);
        setTitle("");
        setAuthors("");
        setCategories("");
        setFormat("");
        setHasChat(false);
        setHasSources(false);
        setPosition("");
        setCustomCover("");

        setStatusMessage({
          "type": STATUS_MESSAGE_TYPE.SUCCESS,
          "text": "Book added successfully",
        });

        setTimeout(() => {
          setStatusMessage(PROMPT_STATUS_MESSAGE);
        }, 3000);
      } catch (error: any) {
        console.error(error);
        setStatusMessage({
          "type": STATUS_MESSAGE_TYPE.ERROR,
          "text": "Error adding book: " + error?.response?.data?.detail ?? error,
        });
      }
    }
  };

  useEffect(() => {
    getUserId(get).then((id: string) => setUserId(id));
  }, []);

  // This runs on every change of any of the form fields
  useEffect(() => {
    if (statusMessage.type === STATUS_MESSAGE_TYPE.PROCESSING) return;

    try {
      const bookDataCandidate: BookData = {
        "book_file": bookFile!,
        "cover_file": coverFile!,
        "slug": slugify(title),
        "title": title.trim(),
        "format": format as BOOK_FORMATS,
        "authors": slugifyAndPack(splitAndFilter(authors)),
        "categories": slugifyAndPack(splitAndFilter(categories)),
        "has_chat_interface": hasChat,
        "has_chat_sources": hasSources,
        "is_public": true,
        "position": position.trim(),
        "custom_cover": customCover.trim(),
      };

      const preparedData = newBookSchema.validateSync(bookDataCandidate);
      const orderedData = Object.fromEntries(fieldsOrder.map((key: string) => [key, preparedData[key]]));

      setValidationMessage(undefined);
      setBookData(orderedData as BookData);
    } catch (error: any) {
      setBookData(undefined);
      if (error.name === "ValidationError") {
        setValidationMessage(error.message);
      } else {
        console.error(error);
        setValidationMessage("Unexpected error: " + error);
      }
    }
  }, [bookFile, coverFile, title, authors, categories, format, hasChat, hasSources, position, customCover]);

  return (
    <BasicLayout type={LAYOUT_TYPE.COLUMNS}>
      <BasicHeader title={"Library Manager (adding books) | Your user ID is " + userId} />
      <div className="flex flex-row">
        <form
          // TODO : add disabled property for the entire form in the component
          className="flex-1 p-5"
          onSubmit={(e) => e.preventDefault()}
        >
          <label className="mb-3 block max-w-xs">
            Book file (PDF or EPUB):
            <br />
            <input
              name="book_file"
              type="file"
              accept=".epub, .pdf"
              onChange={(e) => updateBookFile(e.target.files?.[0])}
            />
          </label>
          <label className="mb-3 block max-w-xs">
            Cover file (PNG):
            <br />
            <input
              name="cover_file"
              type="file"
              accept=".png"
              onChange={(e) => setCoverFile(e.target.files?.[0])}
            />
          </label>
          <label className="mb-3 block">
            Title:
            <br />
            <input
              required
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="border"
            />
          </label>
          <label className="mb-3 block">
            Authors (will be divided based on commas):
            <br />
            <input
              required
              name="authors"
              value={authors}
              onChange={(e) => setAuthors(e.target.value)}
              className="border"
            />
          </label>
          <label className="mb-3 block">
            Categories (will be divided based on commas):
            <br />
            <input
              required
              name="categories"
              value={categories}
              onChange={(e) => setCategories(e.target.value)}
              className="border"
            />
          </label>
          <div className="mb-3">
            <label>
              <input
                type="checkbox"
                name="has_chat_interface"
                checked={hasChat}
                onChange={(e) => setHasChat(e.target.checked)}
              />
              &nbsp;
              Has chat interface
            </label>
          </div>
          <div className="mb-3">
            <label>
              <input
                type="checkbox"
                name="has_chat_sources"
                checked={hasSources}
                onChange={(e) => setHasSources(e.target.checked)}
              />
              &nbsp;
              Has chat sources
            </label>
          </div>
          <label className="mb-3 block">
            (Optional) Position (e.g. "0" or a CFI position):
            <br />
            <input
              name="position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              className="border"
            />
          </label>
          <label className="mb-3 block">
            (Optional) Cover filename (e.g. "cs-161.png") — lowercase & .png:
            <br />
            <input
              name="custom_cover"
              value={customCover}
              onChange={(e) => setCustomCover(e.target.value)}
              className="border"
            />
          </label>
        </form>

        <div className="flex-1 mt-5">
          {statusMessage && (
            <div className={`max-w-xl mb-5 p-2 rounded ${BACKGROUND_COLOR_CLASSES[statusMessage.type]}`}>
              {statusMessage.text}
            </div>
          )}
          {statusMessage.type === STATUS_MESSAGE_TYPE.PROMPT && (
            <>
              {bookData ? (
                <>
                  <p className="mb-4">This will be added to the database:</p>
                  <pre className="text-sm text-left">
                    {JSON.stringify(bookData, null, 2)}
                  </pre>
                  <div className="mt-4">
                    <button onClick={onSaveBook} className="p-2 rounded bg-slate-300 hover:bg-slate-400">
                      Save book to the database
                    </button>
                  </div>
                </>
              ) : (
                <p className="max-w-xl my-3 p-3 rounded text-sm text-left bg-blue-200">
                  {validationMessage ? validationMessage : "---book data isn't ready yet---"}
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </BasicLayout>
  );
};

export default LibraryManager;

import { SessionToken } from "../hooks/useApiCaller";
import { apiCall, METHODS } from "../hooks/useApiCaller";

export const storeApiKey = (apiKey: string, sessionToken: SessionToken) => {
  return apiCall(
    METHODS.POST,
    "/api/api-keys",
    { "api_key": apiKey },
    sessionToken,
  );
};

export const getApiKey = async (sessionToken: SessionToken) => {
  const data = await apiCall(
    METHODS.GET,
    "/api/api-keys",
    undefined,
    sessionToken,
  );

  return data.api_key || null;
};

export const hasApiKey = async (sessionToken: SessionToken) => {
  const apiKey = await getApiKey(sessionToken);
  return !!apiKey;
};

export const deleteApiKey = (sessionToken: SessionToken) => {
  return apiCall(
    METHODS.DELETE,
    "/api/api-keys",
    undefined,
    sessionToken,
  );
};

import React from "react";
import BasicLayout, { LAYOUT_TYPE } from "../components/layout/BasicLayout";

const MobileGate = (): JSX.Element => {
  return (
    <BasicLayout type={LAYOUT_TYPE.CENTERED}>
      <div className="px-12 max-w-md">
        <div className="bg-slate-100 border border-slate-200 p-3 rounded-md flex flex-col items-center gap-2">
          <span className="text-3xl">☹</span>
          <span className="text-center">
            The Library is not yet available on smaller screens. Please use a desktop or laptop computer.
          </span>
        </div>
      </div>
    </BasicLayout>
  );
};

export default MobileGate;

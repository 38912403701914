import React, { useEffect, useState } from "react";
import * as Accordion from "@radix-ui/react-accordion";

import { Book, Collection, User } from "../../types";
import { getUserId } from "../../api/misc";
import { useApiCaller } from "../../hooks/useApiCaller";
import { getUsers } from "../../api/users";
import { getBooksByUserId } from "../../api/books";
import {
  deleteBookFromCollection,
  deleteCollection,
  deleteUserFromCollection,
  getCollections,
  putBookInCollection,
  postCollection,
  putUserInCollection,
} from "../../api/collections";

import BasicLayout, { LAYOUT_TYPE } from "../../components/layout/BasicLayout";
import BasicHeader from "../../components/layout/BasicHeader";
import TextInput from "../../components/shared/forms/TextInput";

const errorMessenger = (e: any) => {
  console.log("error", e);
  if (e.response?.status === 401) {
    window.alert("Not authorized");
  } else {
    window.alert("Something went wrong");
  }
};

const CollectionManager = (): JSX.Element => {
  const { get, post, put, del } = useApiCaller();
  const [collections, setCollections] = useState<Collection[]>([]);
  const [books, setBooks] = useState<Book[]>([]);
  const [newCollectionName, setNewCollectionName] = useState<string>("");
  const [newCollectionSlug, setNewCollectionSlug] = useState<string>("");
  const [newCollectionDescription, setNewCollectionDescription] =
    useState<string>("");
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    getUsers(get).then((users) => {
      setUsers(users);
    });
  }, []);

  useEffect(() => {
    getCollections(get).then((collections) => {
      //  we have to get the books from the api for each collection for some reason
      //  so we do that here
      setCollections(collections);
      // console.log("collections", collections);
    });
    //   we have to send the user_id in the request to get books
    getUserId(get).then((userId) => {
      getBooksByUserId(get, userId).then((books) => {
        // console.log("books", books);
        setBooks(books);
      });
    });
  }, []);

  const addNewCollection = async (
    collection: Collection
  ): Promise<Collection> => {
    // console.log("addNewCollection", collection);
    const newCollection = await postCollection(post, collection);
    setCollections([...collections, newCollection]);
    return newCollection;
  };

  const addBookToCollection = async (
    collection: Collection,
    book: Book
  ): Promise<Collection> => {
    try {
      const updatedCollection = await putBookInCollection(
        put,
        collection,
        book
      );

      setCollections((prevCollections) =>
        prevCollections.map((c) =>
          c.slug === collection.slug ? updatedCollection : c
        )
      );
      return updatedCollection;
    } catch (e: any) {
      errorMessenger(e);
      return collection;
    }
  };

  const removeBookFromCollection = async (
    collection: Collection,
    book: Book
  ): Promise<Collection> => {
    try {
      const updatedCollection = await deleteBookFromCollection(
        del,
        collection,
        book
      );

      setCollections((prevCollections) =>
        prevCollections.map((c) =>
          c.slug === collection.slug ? updatedCollection : c
        )
      );
      return updatedCollection;
    } catch (e: any) {
      errorMessenger(e);
      return collection;
    }
  };

  const toggleBookInCollection = async (
    collection: Collection,
    book: Book
  ): Promise<Collection> => {
    if (collection.books?.some((b) => b.slug === book.slug)) {
      return removeBookFromCollection(collection, book);
    } else {
      return addBookToCollection(collection, book);
    }
  };

  const handleNewCollectionSubmit = async () => {
    if (!newCollectionName || !newCollectionSlug) return;
    try {
      const newCollection: Collection = {
        name: newCollectionName,
        slug: newCollectionSlug,
        description: newCollectionDescription,
      };
      await addNewCollection(newCollection);
      setNewCollectionName("");
      setNewCollectionSlug("");
      setNewCollectionDescription("");
    } catch (e: any) {
      errorMessenger(e);
    }
  };

  const handleDeleteCollection = async (collection: Collection) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this collection?"
    );

    if (userConfirmed) {
      try {
        // add the deletion call here
        await deleteCollection(del, collection);
        // remove the deleted collection from the state
        setCollections(collections.filter((c) => c.slug !== collection.slug));
      } catch (e: any) {
        errorMessenger(e);
      }
    }
  };
  const addUserToCollection = async (
    user: User,
    collection: Collection
  ): Promise<void> => {
    try {
      await putUserInCollection(put, collection, user);
      setUsers((prevUsers) =>
        prevUsers.map((u) =>
          u.id === user.id
            ? { ...u, collections: [...u.collections, collection.slug] }
            : u
        )
      );
    } catch (e: any) {
      errorMessenger(e);
    }
  };

  const removeUserFromCollection = async (
    user: User,
    collection: Collection
  ): Promise<void> => {
    try {
      await deleteUserFromCollection(del, collection, user);
      setUsers((prevUsers) =>
        prevUsers.map((u) => ({
          ...u,
          collections: u.collections?.filter((c) => c !== collection.slug),
        }))
      );
    } catch (e: any) {
      errorMessenger(e);
    }
  };

  const toggleUserCollectionAccess = async (
    user: User,
    collectionSlug: string
  ): Promise<void> => {
    const userHasAccess = user.collections?.some((c) => c === collectionSlug);
    const collection = collections.find((c) => c.slug === collectionSlug);
    if (!collection) return;
    if (userHasAccess) {
      return removeUserFromCollection(user, collection);
    } else {
      return addUserToCollection(user, collection);
    }
  };

  return (
    <>
      <BasicHeader title="Collections Manager" />
      <BasicLayout type={LAYOUT_TYPE.ROWS}>
        {/* Make a two column */}
        <div className="p-4 flex flex-col gap-2 w-3/12">
          <h2 className="text-xl font-bold py-2">New collection</h2>
          <label>Name</label>
          <TextInput
            value={newCollectionName}
            onChange={(e) => setNewCollectionName(e.target.value)}
          />
          <label>Slug</label>
          <TextInput
            value={newCollectionSlug}
            onChange={(e) => setNewCollectionSlug(e.target.value)}
          />
          <label>Description (optional)</label>
          <TextInput
            value={newCollectionDescription}
            onChange={(e) => setNewCollectionDescription(e.target.value)}
          />
          <button
            onClick={handleNewCollectionSubmit}
            disabled={!newCollectionName || !newCollectionSlug}
            className={
              !newCollectionName || !newCollectionSlug
                ? "bg-blue-300 text-white font-bold py-2 px-4 rounded"
                : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            }
          >
            Create
          </button>
        </div>
        <div className="w-full p-4 flex flex-col gap-2">
          <h2 className="text-xl font-bold py-2">Edit collections</h2>

          <Accordion.Root
            type="single"
            defaultValue="item-1"
            collapsible
            className="w-full"
          >
            {collections.map((collection) => (
              <Accordion.Item
                value={collection.slug}
                className=""
                key={collection.slug}
              >
                <Accordion.AccordionHeader
                  className="w-full bg-gray-100 hover:bg-gray-300 p-2 cursor-pointer"
                  asChild={true}
                >
                  <Accordion.AccordionTrigger className="w-full">
                    <div className="flex flex-row justify-between w-full">
                      <span className="">{collection.name}</span>
                      <span>
                        {collection.books ? collection.books.length : 0}
                      </span>
                    </div>
                  </Accordion.AccordionTrigger>
                </Accordion.AccordionHeader>
                <Accordion.AccordionContent className="w-full p-4">
                  <div className="w-full">
                    {collection.description && (
                      <div className="w-full py-2">
                        <p>
                          <i>{collection.description}</i>
                        </p>
                      </div>
                    )}
                    {books.map((book: Book) => {
                      return (
                        <div key={book.slug} className="">
                          <input
                            type="checkbox"
                            checked={collections
                              .find((c) => c.slug === collection.slug)
                              ?.books?.some((b) => b.slug === book.slug)}
                            onChange={() =>
                              toggleBookInCollection(collection, book)
                            }
                          />
                          <span> {book.title}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex flex-row justify-end pt-2">
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                      onClick={() => handleDeleteCollection(collection)}
                    >
                      Delete Collection
                    </button>
                  </div>
                </Accordion.AccordionContent>
              </Accordion.Item>
            ))}
          </Accordion.Root>
        </div>
        <div className="w-full p-4 flex flex-col gap-2">
          <h2 className="text-xl font-bold py-2">Edit users</h2>

          <Accordion.Root
            type="single"
            defaultValue="item-1"
            collapsible
            className="w-full"
          >
            {/* users come sorted by most recent creation date. this seems reasonable */}
            {users.map((user) => (
              <Accordion.Item value={user.id} className="" key={user.id}>
                <Accordion.AccordionHeader
                  className="w-full bg-gray-100 hover:bg-gray-300 p-2 cursor-pointer"
                  asChild={true}
                >
                  <Accordion.AccordionTrigger className="w-full">
                    <div className="flex flex-row justify-start w-full">
                      <img src={user.imageUrl} className="w-6 h-6" />
                      <span className="px-2">
                        {user.firstName} {user.lastName}
                      </span>
                    </div>
                  </Accordion.AccordionTrigger>
                </Accordion.AccordionHeader>
                <Accordion.AccordionContent className="w-full p-4">
                  {collections.map((collection) => (
                    <div key={collection.slug} className="">
                      <input
                        type="checkbox"
                        checked={user.collections?.some(
                          (c) => c === collection.slug
                        )}
                        onChange={() =>
                          toggleUserCollectionAccess(user, collection.slug)
                        }
                      />
                      <span> {collection.name}</span>
                    </div>
                  ))}
                </Accordion.AccordionContent>
              </Accordion.Item>
            ))}
          </Accordion.Root>
        </div>
      </BasicLayout>
    </>
  );
};

export default CollectionManager;

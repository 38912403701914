import { HighlightArea } from "@react-pdf-viewer/highlight";

export const serializeHighlight = (area: HighlightArea): string => {
  return `pdf(${area.pageIndex}:${area.left}:${area.top}:${area.width}:${area.height})`;
};

export const deserializeHighlight = (serialized: string): HighlightArea => {
  const pure = serialized.replace("pdf(", "").replace(")", "");
  const [pageIndex, left, top, width, height] = pure.split(":");

  return {
    pageIndex: parseInt(pageIndex, 10),
    left: parseFloat(left),
    top: parseFloat(top),
    width: parseFloat(width),
    height: parseFloat(height),
  };
};

/**
 * Receives an array of rectangles and returns the minimum rectangle that contains all of them
 * Coordinates are relative to the top-left corner of the page
 * TODO : make it join areas so that it returns not just a bounding rectangle but the minimal set of rectangles
 */
export const getBoundingRectangle = (areas: HighlightArea[]): HighlightArea => {
  const firstArea = areas[0];

  const initialBounds = {
    left: firstArea.left,
    top: firstArea.top,
    bottom: firstArea.top + firstArea.height,
    right: firstArea.left + firstArea.width,
  };

  const bounds = areas.reduce((acc, area) => {
    // Ignore a HighlightArea if it's not on the same page as the first one
    // TODO : add multi-page support
    if (area.pageIndex !== firstArea.pageIndex) {
      return acc;
    }

    // Ignore a HighlightArea if it's associated with the newline character
    if (area.left <= 0 || area.top <= 0) {
      return acc;
    }

    return {
      left: Math.min(acc.left, area.left),
      top: Math.min(acc.top, area.top),
      bottom: Math.max(acc.bottom, area.top + area.height),
      right: Math.max(acc.right, area.left + area.width),
    };
  }, initialBounds);

  // Convert back to the HighlightArea format
  return {
    pageIndex: firstArea.pageIndex,
    left: bounds.left,
    top: bounds.top,
    width: bounds.right - bounds.left,
    height: bounds.bottom - bounds.top,
  };
};

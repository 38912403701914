import React from "react";
import { Modal } from "@mantine/core";

import AddBookForm from "./AddBookForm";

const AddBookTile = (): JSX.Element => {
  const [opened, setOpened] = React.useState(false);

  return (
    <>
      <div
        className="cursor-pointer"
        onClick={() => setOpened(true)}
      >
        <div className="inline-block border-dashed border-4 border-blue-400 rounded-lg h-48 w-32">
          <svg
            className="h-full w-full text-blue-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.0"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
        </div>
        <p className="pt-1">Upload a book</p>
      </div>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Upload your book"
        size="md"
      >
        <AddBookForm />
      </Modal>
    </>
  );
};

export default AddBookTile;

import { configureStore } from "@reduxjs/toolkit";

import libraryReducer from "./state/librarySlice";
import readerReducer from "./state/readerSlice";
import tocReducer from "./state/tocSlice";
import chatReducer from "./state/chatSlice";

const store = configureStore({
  reducer: {
    library: libraryReducer,
    reader: readerReducer,
    toc: tocReducer,
    chat: chatReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ClerkProvider, SignedIn, SignedOut, RedirectToSignIn } from "@clerk/clerk-react";
import { MantineProvider } from "@mantine/core";

// TODO : get rid of toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { config } from "./config";
import { useWindowSize } from "./hooks/useWindowSize";
import { ApiKeyProvider } from "./context/ApiKeyContext";

import CollectionManager from "./pages/admin/CollectionManager";
import LibraryManager from "./pages/admin/LibraryManager";
import MobileGate from "./pages/MobileGate";
import Reader from "./pages/Reader";

const STARTING_URL = "/book/math-51-textbook";

const App = (): JSX.Element => {
  const navigate = useNavigate();
  const { isDesktop } = useWindowSize();

  useEffect(() => {
    if (window.location.pathname === "/") navigate(STARTING_URL);
  }, [navigate]);

  return (
    <ClerkProvider publishableKey={config.REACT_APP_CLERK_PUBLISHABLE_KEY} navigate={(to) => navigate(to)}>
      <SignedIn>
        <ApiKeyProvider>
          <MantineProvider>
            <Routes>
              <Route path="/book/:bookId" element={isDesktop ? <Reader /> : <MobileGate />} />
              <Route path="/admin" element={<h1>Admin</h1>} />"
              <Route path="/admin/library" element={<LibraryManager />} />
              <Route path="/admin/collections" element={<CollectionManager />} />
              <Route path="*" element={<h1>Not Found</h1>} />
            </Routes>
          </MantineProvider>
        </ApiKeyProvider>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
      <ToastContainer position="bottom-left" />
    </ClerkProvider>
  );
};

export default App;

import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Flex, ScrollArea, Skeleton } from "@mantine/core";

import { BookPosition } from "../types";
import { BookSelection } from "../reader/types";

import { useApiCaller } from "../hooks/useApiCaller";
import { useBook } from "../hooks/useBook";
import { TOCProvider } from "../context/TOCContext";
import { preventReactReaderNavigation } from "../reader/epub/ReactReaderWrapper";

import { useAppDispatch, useAppSelector } from "../state/hooks";
import { selectUserHighlights, openBook, jumpTo, setAgentHighlight } from "../state/readerSlice";
import { fetchHighlightsAsync, addHighlightAsync, removeHighlightAsync } from "../state/readerThunks";
import { selectBooks } from "../state/librarySlice";
import { fetchBooksWithPolling } from "../state/libraryThunks";

import BookReader from "../reader/BookReader";

import { useConversations } from "../hooks/useConversations";
import { ConversationProvider } from "../context/ConversationContext";
import Conversation from "../components/content/conversation/Conversation";
import { useAnalytics } from "../analytics/useAnalytics";
import DefaultLayout from "../components/layout/DefaultLayout";

// TODO : implementation details of everything EPUB-, CFI-, or PDF-related should be moved to the reader component

const Reader = (): JSX.Element => {
  const apiCaller = useApiCaller();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { book, bookPosition, setBookPosition } = useBook();
  const { logOnBookOpened } = useAnalytics();

  const highlights = useAppSelector(selectUserHighlights);

  const showTranslationRef = useRef<boolean>(false);

  const visibleBooks = useAppSelector(selectBooks);

  const jumpToAgentHighlight = (bookPosition: BookPosition): void => {
    if (!book) return;
    dispatch(jumpTo(bookPosition));
    dispatch(setAgentHighlight(bookPosition));
  };

  const { conversations, onFetchConversations } = useConversations();

  const onCreateHighlight = async ({ selectionRange, text }: BookSelection): Promise<void> => {
    if (!book) return;
    // TODO : move the existence check to the Redux store
    if (highlights[selectionRange]) throw new Error(`Highlight with CFI range ${selectionRange} already exists`);
    dispatch(addHighlightAsync({ apiCaller, bookSelection: { selectionRange, text } }));
  };

  const onDeleteHighlight = async (bookPosition: BookPosition): Promise<void> => {
    if (!book) return;
    dispatch(removeHighlightAsync({ apiCaller, highlight: highlights[bookPosition] }));
  };

  // DO NOT REMOVE (until ReactReader is no longer used)
  useEffect(() => {
    document.addEventListener("keyup", preventReactReaderNavigation, true);
    return () => document.removeEventListener("keyup", preventReactReaderNavigation, true);
  }, []);

  // Routing logic
  // TODO : probably better to abstract this out and reuse wherever needed
  useEffect(() => {
    if (!visibleBooks) {
      dispatch(fetchBooksWithPolling({ apiCaller }));
    } else {
      const bookId = params.bookId;
      if (!bookId) {
        navigate("/"); // TODO : move to a 418 page
      } else {
        const retrievedBook = visibleBooks?.find((book) => book.slug === bookId);
        if (!retrievedBook) {
          navigate("/"); // TODO : move to a 404 page
        } else {
          dispatch(openBook({ book: retrievedBook }));
          dispatch(fetchHighlightsAsync({ apiCaller }));
          setBookPosition(retrievedBook.position);
          onFetchConversations();

          // useEffect gets executed twice. This is a hack to prevent the second execution
          // retrievedBook has a value both times but book is undefined the first time
          // thats why I track book and not retrievedBook.
          if (typeof book !== "undefined") {
            logOnBookOpened(book);
          }
        }
      }
    }
  }, [visibleBooks]);

  return (
    <TOCProvider>
      <DefaultLayout>
        {!book && (
          <Skeleton height={500} />
        )}
        {book && (
          // TODO: put state of conversation into chatSlice. I had to move the this up into the reader
          // to access useConversationContext inside the archive in the nav bar.
          <ConversationProvider book={book}>
            <Flex>
              <Box w="60%" h="100vh">
                <BookReader
                  book={book}
                  bookPosition={bookPosition}
                  onBookPositionChanged={setBookPosition}
                  highlights={highlights}
                  onCreateHighlight={onCreateHighlight}
                  showTranslationRef={showTranslationRef} // TODO : this should be a state, not a ref
                />
              </Box>

              <ScrollArea w="40%" h="100vh">
                {book.hasChatInterface && <Conversation onSourceClick={jumpToAgentHighlight} />}
              </ScrollArea>

              {/* <div className="absolute flex gap-3 right-5 top-5 z-10 text-gray-400"> */}
              {/* <Popover
                align="end"
                contentComponent={HighlightsList}
                contentProps={{}}
              >
                <button className="font-bold">HL</button>
              </Popover> */}

              {/* <Popover
                align="end"
                contentComponent={ConversationsList}
                contentProps={{
                  conversations,
                  onJumpTo: console.log,
                  onDelete: console.log,
                }}
              >
                <button className="text-gray-400">
                  <CounterClockwiseClockIcon className="w-6 h-6" />
                </button>
              </Popover> */}

              {/* <button onClick={() => navigate("/")}>
                <FaBook size={20} />
              </button> */}
              {/* </div> */}
            </Flex>
          </ConversationProvider>
        )}
      </DefaultLayout>
    </TOCProvider>
  );
};

export default Reader;

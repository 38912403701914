import { Message, SENDERS } from "../reader/types";

export const retrieveHTMLRef = <T extends HTMLElement>(ref: React.RefObject<T | null>): T | null => {
  return ref.current as unknown as T | null;
};

export const timestampToClockTime = (timestamp: Date): string => {
  const hours = timestamp.getHours();
  const minutes = timestamp.getMinutes();
  const amPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${amPm}`;
};

export const truncateText = (text: string, maxLength: number): string => {
  if (text.length > maxLength) {
    return text = text.substring(0, maxLength) + "...";
  } else {
    return text;
  }
};

// Generates a conversation history string from a list of messages.
// It's formatted in a way that in compatible with how the chat history is
// constructed in the backend. Ideally, this in done in the backend. However, it
// is way easier to do it here than to try to do it in the backend. The whole
// implementation is subject to change. In the future, we can handle this in the
// backend.
export const generateConversationHistory = (messages: Message[]): string => {
  const history = messages.reduce((acc, message, index) => {

    // Skip the first message, which is the introduction of the chat model.
    if (index === 0) {
      return "";
    }

    // We don't have to add the prefix "Student: " to the first message, because
    // it's already included in the prompt on the backend.
    if (index === 1) {
      return `${message.text}"\n`;
    }

    return `${acc}${transformSenders(message.sender)}: ${message.text}"\n`;
  }, "");

  return history + transformSenders(SENDERS.USER) + ": ";
};

const transformSenders = (sender: SENDERS): string => {
  if (sender === SENDERS.AI) {
    return "Tutor";
  } else {
    return "Student";
  }
};

import { ChatModelMeta } from "./types";

export const CHAT_MODEL_META: { [key: string]: ChatModelMeta } = {
  // "omni": {
  //   name: "📚 Course Navigator",
  //   slug: "book",
  //   intro:
  //     "Greetings! I am the Course Navigator for {book_title}. I can help you find where things are in the text, and answer questions strictly related to the course's contents. If you are working on a homework problem, consult one of the tutor agents instead.",
  //   error:
  //     "Oh, no! An error occurred while processing your request. Please try again later.",
  //   isStreaming: true,
  //   warningDelayMs: 3000,
  // },
  // "oracle": {
  //   name: "🔮 Oracle",
  //   slug: "thoughtful",
  //   intro:
  //     "Greetings! I am the Oracle, striving to channel the spirit of {author_name}'s thinking. Please inquire here about their text, {book_title}",
  //   error:
  //     "An error occurred while processing your request. Verify API key. Note that this model requires GPT-4 access. Join waitlist: https://openai.com/waitlist/gpt-4-api.",
  //   // Streaming still needs to be supported by all chat models on the backend. I am waiting for the FastAPI <> LangChain bridge to support agents that have agency. It's on the roadmap.
  //   // It's unclear whether we will use such an agent for future use cases, so I haven't written a custom implementation.
  //   isStreaming: false,
  //   warningDelayMs: 10,
  // },
  "chat_with_book": {
    name: "📜 Book Agent",
    slug: "chat_with_book",
    intro:
      // eslint-disable-next-line max-len
      "I am the Oracle, striving to channel the spirit of {author_name}'s thinking. You can ask me anything about the text, {book_title}, and beyond",
    error:
      "Oh, no! An error occurred while processing your request. Please try again later.",
    isStreaming: true,
    warningDelayMs: 5000,
  },
  "sources_tutor": {
    name: "📜 Socratic tutor",
    slug: "sources_tutor",
    intro:
      // eslint-disable-next-line max-len
      "Hello, I am non-judgemental tutor here to help with your homework questions. I have access to {book_title} and can provide relevant sources. Ask away!",
    error:
      "Oh, no! An error occurred while processing your request. Please try again later.",
    isStreaming: true,
    warningDelayMs: 5000,
  },
  // "socratic_tutor": {
  //   name: "📜 Socratic Tutor - fast",
  //   slug: "socratic_tutor",
  //   intro:
  //     "Hello, I am non-judgemental tutor here to help with your homework questions. Ask away!",
  //   error:
  //     "Oh, no! An error occurred while processing your request. Please try again later.",
  //   isStreaming: true,
  //   warningDelayMs: 3000,
  // },
  // "course_tutor": {
  //   name: "📜 Course Tutor",
  //   slug: "course_tutor",
  //   intro:
  //     "Hello, I am non-judgemental tutor here to help with all your needs for the {book_title} course. Ask away!",
  //   error:
  //     "Oh, no! An error occurred while processing your request. Please try again later.",
  //   warningDelayMs: 10000,
  // },
  // "technical_socratic_tutor": {
  //   name: "Technical Socratic Tutor",
  //   slug: "technical_socratic_tutor",
  //   intro:
  //     "Hello, I am non-judgemental tutor here to help with your homework questions. Ask away!",
  //   error:
  //     "Oh, no! An error occurred while processing your request. Please try again later.",
  //   isStreaming: true,
  //   warningDelayMs: 3000,
  // },
  "wolfram_alpha_tutor": {
    name: "🧮 Wolfram Alpha",
    slug: "wolfram_alpha_tutor",
    intro:
      "Hello, I am non-judgemental tutor here to help with your homework questions. I use resources from the {book_title}, as well as Wikipedia and Wolfram Alpha to answer your technical questions. I will warn you that I take time to think, around 40s per question. For realtime dialogue, try the fast tutor instead.",
    error:
      "Oh, no! An error occurred while processing your request. Please try again later.",
    isStreaming: false,
    warningDelayMs: 10000,
  },
};

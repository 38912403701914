export const slugify = (value: string): string => {
  return value.toLowerCase().replace(/[^a-z0-9]+/g, "-").replace(/^-|-$/g, "");
};

export const slugifyAndPack = (entities: string[]): { name: string, slug: string }[] => {
  return entities.map((item: string) => ({ "name": item, "slug": slugify(item) }));
};

export const splitAndFilter = (value: string): string[] => {
  return value.split(",")
    .map((item: string) => item.trim())
    .filter((item: string) => item);
};

import React from "react";
import PdfReaderWrapper from "./pdf/PdfReaderWrapper";
import ReactReaderWrapper from "./epub/ReactReaderWrapper";

import { Book, BookPosition } from "../types";
import { BookSelection, Highlights } from "./types";
import { useTOC } from "../context/TOCContext";
import { useAppSelector } from "../state/hooks";
import { selectAgentHighlight } from "../state/readerSlice";

// TODO : move to constants
export enum BOOK_FORMATS {
  PDF = "pdf",
  EPUB = "epub",
}

type BookReaderProps = {
  book: Book;
  bookPosition?: BookPosition;
  onBookPositionChanged: (position: BookPosition) => void;
  highlights: Highlights;
  onCreateHighlight: (bookSelection: BookSelection) => Promise<void>;
  showTranslationRef: React.MutableRefObject<boolean>;
};

/**
 * Routes different types of books to the appropriate readers.
 */
const BookReader = ({
  book,
  bookPosition,
  onBookPositionChanged,
  highlights,
  onCreateHighlight,
  showTranslationRef,
}: BookReaderProps): JSX.Element => {
  const agentHighlight = useAppSelector(selectAgentHighlight);

  const { bookmarkPluginInstance } = useTOC();

  if (book.format === BOOK_FORMATS.PDF) {
    return (
      <PdfReaderWrapper
        book={book}
        bookPosition={bookPosition}
        onBookPositionChanged={onBookPositionChanged}
        // highlights={highlights}
        // onCreateHighlight={onCreateHighlight}
        bookmarkPluginInstance={bookmarkPluginInstance}
      />
    );
  } else if (book.format === BOOK_FORMATS.EPUB) {
    return (
      <ReactReaderWrapper
        bookUrl={book.src}
        bookPosition={bookPosition}
        onBookPositionChanged={onBookPositionChanged}
        highlights={highlights}
        onCreateHighlight={onCreateHighlight}
        agentHighlight={agentHighlight}
        showTranslationRef={showTranslationRef}
      />
    );
  } else {
    throw new Error(`Unsupported file extension: ${book.format}`);
  }
};

export default BookReader;
